import styled from 'styled-components';

import { COLORS, BREAKPOINTS } from '../../../utils/theme';

export const Legend = styled.legend`
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: -0.2px;
  text-align: center;
  width: 100%;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const Fieldset = styled.fieldset`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 4px;
  border: 0;
  margin: 0;
  min-width: 0;
  margin-block: 35px;
  text-align: center;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    flex-direction: column;
    margin-inline: 0;
    padding-inline: 0;
    padding-block: 0;
    row-gap: 24px;
    margin-block: 24px;
  }
`;

export const TextareaDescriptionContainer = styled.div`
  padding-bottom: 32px;
  display: flex;
  text-align: left;
  justify-content: space-between;
  column-gap: 80px;
  max-width: 700px;
  margin: 0 auto;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    flex-direction: column;
    row-gap: 20px;
  }
`;

export const ListTitle = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;

export const ListOptions = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  & > li + li {
    margin-top: 20px;
  }
`;

export const Option = styled.li`
  color: ${COLORS.DARK_GREY};
  font-size: 14px;
  line-height: 20px;
  position: relative;
  opacity: 0.9;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    padding-left: 28px;
  }
`;

export const CheckImage = styled.img`
  position: absolute;
  top: 5px;
  left: -28px;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    left: 0px;
  }
`;

export const ImageCloud = styled.img`
  position: absolute;
  width: 350px;
  height: auto;
  top: -100px;
  right: 0;
  transform: scaleX(-1) rotate(-45deg);

  @media (max-width: ${BREAKPOINTS.DESKTOP}px) {
    width: 250px;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    width: 200px;
    right: auto;
    left: -60px;
    top: -60px;
    transform: scaleY(-1) rotate(70deg);
  }
`;

export const ImageYellow = styled.img`
  position: absolute;
  bottom: -100px;
  left: -50px;
  width: 450px;
  height: auto;
  transform: scaleX(-1) rotate(-10deg);

  @media (max-width: ${BREAKPOINTS.DESKTOP}px) {
    width: 300px;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    width: 250px;
    left: auto;
    right: -20px;
    bottom: -120px;
  }
`;

export const ImageDotted = styled.img`
  position: absolute;
  top: -120px;
  right: -100px;
  width: 450px;
  transform: scaleX(-1) rotate(100deg);

  @media (max-width: ${BREAKPOINTS.DESKTOP}px) {
    width: 300px;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    width: 180px;
    right: auto;
    left: 20px;
    top: -90px;
  }
`;

export const CaptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .captions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 8px;
    column-gap: 24px;

    @media (min-width: ${BREAKPOINTS.PHABLET}px) {
      flex-direction: row;
    }
  }

  .note {
    max-width: 480px;
    margin: 0 auto;
  }
`;
