import React, { useState, Fragment, forwardRef } from 'react';
import { Combobox } from '@headlessui/react';
import { Controller } from 'react-hook-form';
import { COLORS } from '../../../utils/theme';
import { isIOS } from '../../../utils/device';

import ErrorMessage from '../ErrorMessage';
import * as Styled from './styles';

function MyCombobox({
  id,
  options,
  value,
  onChange,
  onBlur,
  multiple,
  hideSearchInput,
  disabled = false,
}) {
  const [query, setQuery] = useState('');

  const filteredOptions =
    query === ''
      ? options
      : options?.filter((option) => {
          return option.name?.toLowerCase().includes(query.toLowerCase());
        });

  const onValueChange = (e) => {
    onChange(e);
    setQuery('');
  };

  return (
    <Combobox
      disabled={disabled}
      multiple={multiple}
      value={value}
      name={id}
      onChange={onValueChange}
    >
      {/* <span>{query}</span> */}
      <Combobox.Button
        style={{
          position: 'absolute',
          left: '0px',
          top: '0px',
          border: 'none',
          background: 'transparent',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
        as={forwardRef(function AccessibleButton(props, ref) {
          return <Styled.Dropdown {...props} tabIndex={0} ref={ref} />;
        })}
      >
        <span className="sr-only">Open</span>
      </Combobox.Button>
      {!disabled && <Styled.ArrowDownImage />}
      <Styled.ComboboxOptions
        as={Combobox.Options}
        onMouseDown={(e) => {
          if (!isIOS()) {
            e.preventDefault();
          }
        }}
      >
        {!hideSearchInput && (
          <div style={{ position: 'relative', color: COLORS.BLACK, paddingInline: '12px' }}>
            <Styled.SvgIcon xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none">
              <circle cx="9" cy="5.5" r="4.4" stroke="currentColor" stroke-width="1.2" />
              <path stroke="currentColor" stroke-width="1.2" d="m5.42 8.92-4.24 4.25" />
            </Styled.SvgIcon>
            <Styled.InputText
              placeholder="Type to search"
              as={Combobox.Input}
              onChange={(event) => setQuery(event.target.value)}
              onBlur={onBlur}
            />
          </div>
        )}
        {filteredOptions?.length === 0 && query !== '' ? (
          <div>Nothing found.</div>
        ) : (
          <>
            {filteredOptions?.map((option) => (
              <Styled.ComboboxOption as={Combobox.Option} key={option.id} value={option.id}>
                {({ selected, active }) => (
                  <Styled.OptionList data-active={active} data-selected={selected}>
                    <Styled.Checkbox data-selected={selected} multiple={multiple}>
                      {selected &&
                        (multiple ? (
                          <svg xmlns="http://www.w3.org/2000/svg" width="9" height="7" fill="none">
                            <path
                              stroke="currentColor"
                              stroke-width="1.2"
                              d="M7.93.63 3.3 5.27 1 2.98"
                            />
                          </svg>
                        ) : (
                          <div
                            style={{
                              width: '7px',
                              height: '7px',
                              background: COLORS.WHITE,
                              borderRadius: '999px',
                            }}
                          ></div>
                        ))}
                    </Styled.Checkbox>
                    <span>{option.name}</span>
                  </Styled.OptionList>
                )}
              </Styled.ComboboxOption>
            ))}
          </>
        )}
      </Styled.ComboboxOptions>
    </Combobox>
  );
}

const StepInputSelect = ({
  label,
  id,
  register,
  options,
  placeholder,
  errors,
  forcedErrorMessage,
  control,
  multiple = false,
  minWidth = false,
  extraPadding = false,
  hideSearchInput = false,
  inputBeforeLabel = false,
  layout,
  disabled = false,
}) => {
  const getNameFromValue = (value) => {
    if (!value) return;
    if (typeof value === 'object' && !value.isoCode) {
      const filteredOptions = options
        ?.filter((option) => value?.includes(option.id))
        .map((el) => el.name);
      if (filteredOptions?.length > 1) {
        const last = filteredOptions.pop();
        filteredOptions.push(`and ${last}`);
      }

      return filteredOptions?.join(', ') || '';
    } else {
      const option = options?.find((option) => option.id === value);
      return option?.name || '';
    }
  };

  const hasValue = (value) => {
    if (typeof value === 'object') {
      return value?.length;
    } else {
      return !!value;
    }
  };

  return (
    <Styled.QuestionContainer
      extraPadding={extraPadding}
      inputBeforeLabel={inputBeforeLabel}
      layout={layout}
    >
      {label && <Styled.SelectLabel htmlFor={id}>{label}</Styled.SelectLabel>}
      <Controller
        name={id}
        control={control}
        render={({ field }) => (
          <>
            <Styled.OptionContainer>
              <Styled.SelectedOption
                disabled={disabled}
                minWidth={minWidth}
                value={hasValue(field.value)}
              >
                {getNameFromValue(field.value) || (
                  <span style={{ opacity: '0.6' }}>{placeholder}</span>
                )}
              </Styled.SelectedOption>
              <MyCombobox
                id={id}
                options={options}
                value={options?.length && field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                multiple={multiple}
                hideSearchInput={hideSearchInput}
                disabled={disabled}
              ></MyCombobox>
            </Styled.OptionContainer>
          </>
        )}
      ></Controller>
      {(forcedErrorMessage || errors?.[id]?.message) && (
        <ErrorMessage error={forcedErrorMessage || errors?.[id]?.message} />
      )}
    </Styled.QuestionContainer>

    //   <Styled.AnswersContainer>
    //     <Styled.SelectOptions id={id} name={id} {...register(id)}>
    //       <Styled.QuestionOptions value="">Select an option</Styled.QuestionOptions>
    //       {options?.map((option) => (
    //         <Styled.QuestionOptions key={option.id} value={option?.id}>
    //           {option?.name}
    //         </Styled.QuestionOptions>
    //       ))}
    //     </Styled.SelectOptions>
    //   </Styled.AnswersContainer>
  );
};

export default StepInputSelect;
