import styled from 'styled-components';
import { H1, Pb, BREAKPOINTS } from '../../../utils/theme';

export const HeaderContainer = styled.div`
  background: ${({ bgColor }) => bgColor};
  padding: 8rem 1.25rem;
  padding-top: 13rem;
  text-align: center;
  position: relative;
  z-index: 10;

  ${(props) => {
    if (props.finalStep) {
      return `padding-bottom: 3rem;`;
    }
  }}

  ${(props) => {
    if (!props.finalStep) {
      return `box-shadow: 0px 0px 90px 0px #2b2b2b33;`;
    }
  }}


  ${(props) => {
    if (props.finalStep) {
      return `
      @media (max-width: ${BREAKPOINTS.PHABLET}px) {
        padding-top: 0rem;
      }
    `;
    }
  }}
`;

export const HeaderTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  max-width: 41rem;
  margin: 0 auto;
  text-wrap: balance;
  position: relative;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    gap: 1.1rem;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    max-width: 20rem;
  }
`;

export const HeaderTitle = styled(H1)`
  margin: 0;
  color: ${({ textColor }) => textColor};
  line-height: 1.1;

  font-size: 40px;

  ${(props) => {
    if (props.finalStep) {
      return `
      padding-bottom: 40px;
      max-width: 550px;
      `;
    }
  }}

  & > span {
    position: relative;
  }

  & > span:before {
    content: '';
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    ${(props) => {
      if (props.step === 0) {
        return `
        background-image: url('/images/RegistrationHeader/circle.png');
        bottom: -50px;
        left: -40px;
        height: 150px;
        width: 220px;

        @media(max-width: ${BREAKPOINTS.TABLET}px){
          width: 160px;
          height: 130px;
        }
        `;
      }

      if (props.step === 1) {
        return `
        background-image: url('/images/RegistrationHeader/curved-line.png');
        bottom: -25px;
        left: -20px;
        height: 40px;
        width: 150%;
        `;
      }
    }}
  }

  ${(props) => {
    if (props.step === 2) {
      return `
      & > span:last-child{
        display: block;
        width: fit-content;
        margin: 0 auto;
      }

      & > span:first-child:before {
      background-image: url('/images/RegistrationHeader/circle.png');
      bottom: -60px;
      left: -40px;
      height: 150px;
      width: 220px;
      }

      @media(max-width: ${BREAKPOINTS.TABLET}px){
      & > span:first-child:before {
        width: 160px;
        height: 130px;
        bottom: -50px;
      }
      }

      & > span:last-child:before {
      background-image: url('/images/RegistrationHeader/short-line.png');
      bottom: -20px;
      left: 0px;
      height: 30px;
      width: 100%;
      }
      `;
    }

    if (props.step === 3) {
      return `
      & > span:first-child:before {
      background-image: url('/images/RegistrationHeader/big-circle.png');
      bottom: -80px;
      left: -100px;
      height: 230px;
      width: 360px;
      }

      & > span:last-child:before {
      background-image: url('/images/RegistrationHeader/line-white.png');
      bottom: -30px;
      left: 0px;
      height: 50px;
      width: 100%;
      }

      @media(max-width: ${BREAKPOINTS.PHABLET}px){
      & > span:last-child:before {
        left: -235px;
        width: 200px;
        }
      }
      `;
    }
  }}

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    font-size: 50px;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    ${(props) => {
      if (props.finalStep) {
        return `
      padding-block: 136px;
      `;
      }
    }}
  }
`;

export const HeaderText = styled(Pb)`
  ${(props) => {
    if (props.smallDescription && props.step === 3) {
      return `
      @media(max-width: ${BREAKPOINTS.PHABLET}px){
        display: none;
      }
      `;
    }
  }}
  font-size: ${(props) => (props.finalStep ? '18px' : props.smallDescription ? '16px' : '24px')};
  line-height: ${(props) => (props.smallDescription ? '1.25' : '1.25')};
  opacity: 0.9;
  margin: 0 auto;
  text-align: center;
  color: ${({ textColor }) => textColor};

  ${(props) => {
    if (props.finalStep) {
      return `
      max-width: 500px;
      `;
    }
  }}

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    font-size: ${(props) => (props.finalStep ? '22px' : props.smallDescription ? '18px' : '28px')};
  }

  & > span {
    position: relative;
  }

  & > span:before {
    content: '';
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;

    ${(props) => {
      if (props.step === 0) {
        return `
        background-image: url('/images/RegistrationHeader/line-blue.png');
        bottom: -40px;
        left: 0;
        height: 60px;
        width: 100%;
        `;
      }
    }}
  }
`;

export const LinesLeft = styled.img`
  position: absolute;
  width: 250px;
  height: auto;
  top: -135px;
  left: ${(props) => (props.hasUserId ? '-15px' : '-85px')};
  transform: rotate(30deg);

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    width: 200px;
    /* left: -60px; */
    left: ${(props) => (props.hasUserId ? '40px' : '-20px')};
    top: -110px;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    display: none;
  }
`;

export const LinesRight = styled.img`
  position: absolute;
  width: 250px;
  height: auto;
  top: ${(props) => (props.hasUserId ? '-35px' : '-20px')};
  right: -120px;
  transform: scaleX(-1) rotate(-35deg);

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    width: 200px;
    top: -20px;
    right: ${(props) => (props.hasUserId ? '-40px' : '-40px')};
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    display: none;
  }
`;
