import React from 'react';
import { COLORS } from '../../../utils/theme';
import { TEXTS } from '../../../utils/texts';

import StepHeader from '../StepHeader';
import StepInputText from '../StepInputText';
import StepInputTextarea from '../StepInputTextarea';
import StepInputSelect from '../StepInputSelect';
import StepInputCheckbox from '../StepInputCheckbox';
import FormSection from '../FormSection';
import StepInputFile from '../StepInputFile';
import StepInputSingleCheckbox from '../StepInputSingleCheckbox';

import { useFieldArray } from 'react-hook-form';

import * as Styled from './styles';
import * as StyledFormSection from '../FormSection/styles';
import { CaptionsWrapper as StyledCaptionsWrapper } from '../FirstStep/styles';
import { DEFAULT_INPUT_MAX_LENGTH } from '../../../utils/constants';

const SecondStep = ({
  errors,
  register,
  artTypes,
  themes,
  socialMediaType,
  countries,
  mainSectorType,
  workScale,
  control,
  watch,
  setValue,
  userInfo,
  step,
  imageInitialId,
  imagesOfWorkInitialIds,
  isOrganization,
  isMobile,
}) => {
  const socialMediaFieldArray = useFieldArray({
    control,
    name: 'socialMediaLinks',
  });
  const workLinksArray = useFieldArray({
    control,
    name: 'workLinks',
  });

  const artTypesSelectedIds = watch('artTypes');
  const artTypesOtherId = String(artTypes?.data?.find((el) => el.attributes.name === 'Other')?.id);
  const showArtTypesOther = artTypesSelectedIds?.length
    ? artTypesSelectedIds?.includes(artTypesOtherId)
    : false;

  const themesSelectedIds = watch('themes');
  const themesOtherId = String(themes?.data?.find((el) => el.attributes.name === 'Other')?.id);
  const showThemesOther = themesSelectedIds?.length
    ? themesSelectedIds?.includes(themesOtherId)
    : false;

  return (
    <div>
      <StepHeader
        title={isOrganization ? "Your organisation's <span>work</span>" : 'Your <span>work</span>'}
        subtitle="In this section, we’d like to learn more about the scope of your work."
        bgColor={COLORS.PINK}
        step={step}
      />

      <FormSection title={isOrganization ? 'Area of work' : 'Type of work'}>
        <StepInputSelect
          label={
            isOrganization
              ? "is at the core of our organisation's work/what we do"
              : 'is at the core of my work/what I do.'
          }
          id="mainSectorType"
          register={register}
          placeholder="Work Area"
          hideSearchInput={true}
          inputBeforeLabel={true}
          options={mainSectorType?.data?.map((el) => ({
            name: el?.attributes?.name,
            id: el?.id,
          }))}
          errors={errors}
        />

        <StepInputCheckbox
          label={
            isOrganization
              ? 'Our work is mostly in the following artistic disciplines:'
              : 'The artistic disciplines I mostly work in are:'
          }
          description="Select only applicable disciplines. You may identify more than one."
          id="artTypes"
          register={register}
          options={artTypes?.data?.map((el) => ({
            label: el?.attributes?.name,
            id: el?.id,
          }))}
          errors={errors}
        />
        {showArtTypesOther && (
          <StepInputText
            label="Other types"
            id="artTypesOther"
            placeholder="Other"
            hideLabel={true}
            register={register}
            maxLength={DEFAULT_INPUT_MAX_LENGTH}
          />
        )}

        <StepInputCheckbox
          label={isOrganization ? 'Our areas of focus are:' : 'My areas of focus are:'}
          description="Select only applicable areas of focus. You may identify more than one."
          id="themes"
          register={register}
          options={themes?.data?.map((el) => ({
            label: el?.attributes?.name,
            id: el?.id,
          }))}
          errors={errors}
        />
        {showThemesOther && (
          <StepInputText
            label="Other themes"
            id="themesOther"
            placeholder="Other"
            hideLabel={true}
            register={register}
            maxLength={DEFAULT_INPUT_MAX_LENGTH}
          />
        )}

        <div style={{ position: 'absolute', inset: 0, overflow: 'hidden', pointerEvents: 'none' }}>
          <Styled.ImageCloud src="/images/cloud-blue.png" alt="" width="928" height="992" />
        </div>
      </FormSection>

      <FormSection title={isOrganization ? 'Scale of work' : 'Scale of your work'}>
        <StepInputSelect
          label={isOrganization ? 'The scale of our work is' : 'The scale of my work is'}
          id="workScale"
          register={register}
          placeholder="Scale"
          hideSearchInput={true}
          options={workScale?.data?.map((el) => ({
            name: el?.attributes?.name,
            id: el?.id,
          }))}
          errors={errors}
          multiple={true}
        />

        <div>
          <StepInputSelect
            label="Mostly operating in "
            id="countries"
            register={register}
            placeholder="Country"
            multiple={true}
            options={countries?.data?.map((el) => ({
              name: el?.attributes?.name,
              id: el?.id,
            }))}
            errors={errors}
          />

          <p
            style={{
              margin: '0 auto',
              fontSize: '14px',
              fontWeight: '500',
              color: COLORS.DARK_GREY,
              paddingTop: '8px',
            }}
          >
            Please be as specific as possible
          </p>
        </div>

        <div style={{ position: 'absolute', inset: 0, overflow: 'hidden', pointerEvents: 'none' }}>
          <Styled.ImageOrange src="/images/orange.png" alt="" width="1013" height="1070" />
        </div>
      </FormSection>

      <FormSection title="Images of your work">
        {isOrganization && (
          <>
            <StyledFormSection.SectionFormDescription>
              Please upload a main image of your work to use as your profile picture.
            </StyledFormSection.SectionFormDescription>

            <StepInputFile
              label="Choose profile image"
              id="image"
              isOrganization={isOrganization}
              userInfo={userInfo}
              setValue={setValue}
              initialIds={imageInitialId}
              isMobile={isMobile}
            >
              <StyledCaptionsWrapper>
                <div className="captions">
                  <p style={{ margin: 0 }}>Image size: up to 10MB</p>
                  <p style={{ margin: 0 }}>Files supported: .png or .jpg</p>
                  <p style={{ margin: 0 }}>Square pictures work best</p>
                </div>
                <div className="note">
                  <p>
                    Note: The image will be resized to fit a square format; however, CAN will retain
                    and save the image in its original format.
                  </p>
                </div>
              </StyledCaptionsWrapper>
            </StepInputFile>

            <div style={{ marginTop: '1rem' }}>
              <StepInputSingleCheckbox
                id="imageOwnerConfirmation"
                label={TEXTS.profile.getOwnerConfirmation(isOrganization)}
                register={register}
                errors={errors}
              />
            </div>

            <div style={{ marginTop: '30px' }}>
              <StepInputTextarea
                label="Photo information"
                id="imageCredits"
                placeholder="Photo information"
                maxWidthType="480px"
                register={register}
                errors={errors}
                watch={watch}
                rows={1}
                maxLength={DEFAULT_INPUT_MAX_LENGTH}
              />
            </div>
            <StyledFormSection.SectionFormDescription style={{ marginTop: '0.2rem' }}>
              eg: a photo credit, location of the event, etc.
            </StyledFormSection.SectionFormDescription>
          </>
        )}

        <StyledFormSection.SectionFormDescription
          style={{ marginTop: isOrganization ? '2rem' : 0 }}
        >
          Please provide <strong>up to three {isOrganization ? 'additional' : ''} photos</strong>{' '}
          that represent your work.
        </StyledFormSection.SectionFormDescription>

        <StepInputFile
          label="Choose images of work"
          id="imagesOfWork"
          isOrganization={isOrganization}
          userInfo={userInfo}
          maxFiles={3}
          setValue={setValue}
          initialIds={imagesOfWorkInitialIds}
          isMobile={isMobile}
          showNumbers
        >
          <StyledCaptionsWrapper>
            <div className="captions">
              <p style={{ margin: 0 }}>Image size: up to 10MB</p>
              <p style={{ margin: 0 }}>Files supported: .png or .jpg</p>
              <p style={{ margin: 0 }}>Square pictures work best</p>
            </div>
            <div className="note">
              <p>
                Note: The images will be resized to fit a square format; however, CAN will retain
                and save the images in its original format.
              </p>
            </div>
          </StyledCaptionsWrapper>
        </StepInputFile>

        <div style={{ marginTop: '1.5rem' }}>
          <StepInputSingleCheckbox
            id="imagesOfWorkOwnerConfirmation"
            label={TEXTS.profile.getOwnerConfirmation(isOrganization, true)}
            register={register}
            errors={errors}
          />
        </div>

        <div style={{ marginTop: '30px' }}>
          <StepInputTextarea
            label="Images of work information"
            id="imagesOfWorkCredits"
            placeholder="Photos information"
            maxWidthType="800px"
            register={register}
            errors={errors}
            watch={watch}
            rows={1}
            maxLength={DEFAULT_INPUT_MAX_LENGTH}
          />
          <StyledFormSection.SectionFormDescription style={{ marginTop: '0.2rem' }}>
            Please, describe the images from left to right.
            <br />( eg: a photo credit, location of the event, etc.)
          </StyledFormSection.SectionFormDescription>
        </div>

        <div style={{ position: 'absolute', inset: 0, overflow: 'hidden', pointerEvents: 'none' }}>
          <Styled.ImageDotted src="/images/dotted-blue.png" alt="" width="913" height="1074" />
        </div>
      </FormSection>

      <FormSection title={isOrganization ? 'Links to content' : 'Links to your work'}>
        <StyledFormSection.SectionFormDescription>
          <Styled.MainSectionDescription>
            We would love to explore your work. Please share links to content representing your
            work.
          </Styled.MainSectionDescription>
        </StyledFormSection.SectionFormDescription>
        <Styled.QuestionWrapper>
          <StepInputText
            label={isOrganization ? 'Organisation website' : 'Your own website'}
            id="website"
            description={
              isOrganization
                ? 'Please provide a link to your organisation’s website, if you have one.'
                : 'Please share a link to your website, if you have one.'
            }
            register={register}
            placeholder="Website URL"
            errors={errors}
            additionalPadding={true}
          />

          <Styled.SocialMediaContainer>
            <Styled.SectionTitle>Social media links</Styled.SectionTitle>
            {socialMediaFieldArray.fields?.length ? (
              <Styled.SocialMediaListOptions>
                {socialMediaFieldArray.fields.map((item, index) => (
                  <Styled.SocialMediaOption key={index}>
                    <Styled.OptionContainer>
                      <StepInputSelect
                        id={`socialMediaLinks.${index}.type.id`}
                        placeholder="Social media"
                        options={socialMediaType?.data?.map((option) => ({
                          name: option.attributes.name,
                          id: option.id,
                        }))}
                        hideSearchInput={true}
                        register={register}
                        forcedErrorMessage={errors?.['socialMediaLinks']?.[index]?.type?.id.message}
                        layout="always-column"
                        minWidth={true}
                      ></StepInputSelect>
                      <StepInputText
                        id={`socialMediaLinks.${index}.link`}
                        placeholder="Social link url"
                        register={register}
                        forcedErrorMessage={errors?.['socialMediaLinks']?.[index]?.link?.message}
                      />
                    </Styled.OptionContainer>
                    <button
                      style={{
                        background: 'none',
                        border: 'none',
                      }}
                      type="button"
                      onClick={() => socialMediaFieldArray.remove(index)}
                    >
                      <img
                        style={{ width: '25px', height: '28px' }}
                        src="/images/cross.png"
                        width="86"
                        height="93"
                        alt=""
                      ></img>
                    </button>
                  </Styled.SocialMediaOption>
                ))}
              </Styled.SocialMediaListOptions>
            ) : null}

            <button
              style={{
                marginTop: '16px',
                background: 'none',
                border: `1px solid ${COLORS.BLUE}`,
                borderRadius: '9999px',
                paddingInline: '24px',
                paddingBlock: '10px',
                color: COLORS.BLUE,
                fontSize: '14px',
              }}
              type="button"
              onClick={() => socialMediaFieldArray.append({}, { shouldFocus: false })}
            >
              + Add social media link
            </button>
          </Styled.SocialMediaContainer>
        </Styled.QuestionWrapper>

        <Styled.QuestionWrapper>
          <Styled.SectionTitle>Work links</Styled.SectionTitle>
          <Styled.SectionDescription>
            {isOrganization
              ? 'Please provide links to content that reflects your organisation’s work'
              : 'Articles, projects, etc.'}
          </Styled.SectionDescription>
          {workLinksArray.fields?.length ? (
            <Styled.WorkLinksListOptions>
              {workLinksArray.fields.map((item, index) => (
                <Styled.WorkLinkOption key={item.id}>
                  <StepInputText
                    id={`workLinks.${index}.link`}
                    placeholder="Work link url"
                    register={register}
                    widthFull={true}
                    layout="always-column"
                    forcedErrorMessage={errors?.['workLinks']?.[index]?.link?.message}
                  />
                  <button
                    style={{
                      background: 'none',
                      border: 'none',
                    }}
                    type="button"
                    onClick={() => workLinksArray.remove(index)}
                  >
                    <img
                      style={{ width: '25px', height: '28px' }}
                      src="/images/cross.png"
                      width="86"
                      height="93"
                      alt=""
                    ></img>
                  </button>
                </Styled.WorkLinkOption>
              ))}
            </Styled.WorkLinksListOptions>
          ) : null}
          <button
            style={{
              marginTop: '16px',
              background: 'none',
              border: `1px solid ${COLORS.BLUE}`,
              borderRadius: '9999px',
              paddingInline: '24px',
              paddingBlock: '10px',
              color: COLORS.BLUE,
              fontSize: '14px',
            }}
            type="button"
            onClick={() => workLinksArray.append()}
          >
            + Add work link
          </button>
        </Styled.QuestionWrapper>

        <div style={{ position: 'absolute', inset: 0, overflow: 'hidden', pointerEvents: 'none' }}>
          <Styled.ImageYellow src="/images/yellow.png" alt="1342" width="1285"></Styled.ImageYellow>
        </div>
      </FormSection>
    </div>
  );
};

export default SecondStep;
