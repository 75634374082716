import styled from 'styled-components';

import { COLORS, BREAKPOINTS } from '../../../utils/theme';

export const Fieldset = styled.fieldset`
  border: 0;
  text-align: left;
  display: flex;
  row-gap: 12px;
  flex-direction: column;
  margin: 0 auto;
  color: ${COLORS.DARK_GREY};
  font-size: 20px;
  line-height: 32px;
  max-width: 680px;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    font-size: 16px;
    line-height: 24px;
    padding-inline: 0px;
  }
`;

export const Input = styled.input`
  accent-color: ${COLORS.BLUE};
`;

export const Label = styled.label`
  opacity: 0.8;
  margin-left: 10px;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    margin-left: 5px;
  }
`;

export const SocialMediaContainer = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    column-gap: 5px;
  }
`;
