import React from 'react';

import ErrorMessage from '../ErrorMessage';

import * as Styled from './styles';

const StepInputSingleCheckbox = ({ label, id, register, errors }) => {
  return (
    <>
      <div>
        <Styled.Container>
          <Styled.InputCheckbox type="checkbox" id={id} name={id} {...register(id)} />
          <Styled.Label htmlFor={id}>{label}</Styled.Label>
        </Styled.Container>
      </div>
      {errors?.[id]?.message && <ErrorMessage error={errors?.[id]?.message} />}
    </>
  );
};

export default StepInputSingleCheckbox;
