import React from 'react';

import * as Styled from './styles';
import * as StyledFormSection from '../FormSection/styles';
import ErrorMessage from '../ErrorMessage';

const StepInputCheckbox = ({ label, description, register, children, options, id, errors }) => {
  return (
    <Styled.QuestionContainer>
      {children}
      <Styled.Legend>{label}</Styled.Legend>
      <StyledFormSection.SectionFormDescription checkbox={true}>
        {description}
      </StyledFormSection.SectionFormDescription>
      <Styled.AnswersContainer>
        {options?.map((option, idx) => {
          return (
            <Styled.AnswerWrapper key={idx}>
              <Styled.InputCheckbox
                type="checkbox"
                id={`${id}-${option.id}`}
                name={id}
                value={option.id}
                idx={idx % 3}
                {...register(id)}
              />
              <Styled.AnswerLabel htmlFor={`${id}-${option.id}`} label={option.label}>
                <span style={{ position: 'relative', zIndex: '2' }}>{option.label}</span>
              </Styled.AnswerLabel>
            </Styled.AnswerWrapper>
          );
        })}
      </Styled.AnswersContainer>
      {errors?.[id]?.message && <ErrorMessage error={errors?.[id]?.message} />}
    </Styled.QuestionContainer>
  );
};

export default StepInputCheckbox;
