import styled from 'styled-components';
import { COLORS, BREAKPOINTS } from '../../../utils/theme';

export const QuestionWrapper = styled.div``;

export const SocialMediaOption = styled.li`
  display: flex;
  align-items: center;
  column-gap: 8px;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    width: 100%;
    align-items: flex-start;
  }
`;

export const OptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    flex-direction: column;
  }
`;

export const SocialMediaListOptions = styled.ul`
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 16px;

  padding-top: 15px;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    max-width: 402px;
  }
`;

export const WorkLinksListOptions = styled.ul`
  display: flex;
  row-gap: 16px;
  flex-direction: column;
  max-width: 402px;
  padding-inline-start: 0px;
  margin: 0 auto;

  padding-top: 15px;
`;

export const WorkLinkOption = styled.li`
  list-style: none;
  display: flex;
  justify-content: center;
  column-gap: 8px;
  width: 100%;
`;

export const SectionTitle = styled.h3`
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.2px;
  text-align: center;
  padding: 0;
  margin: 0;
`;

export const SectionDescription = styled.p`
  max-width: 513px;
  margin: 0 auto;

  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: ${COLORS.DARK_GREY};
  opacity: 0.8;

  text-align: center;
  text-wrap: balance;

  @media (min-width: ${BREAKPOINTS.PHABLET}px) {
    font-size: 16px;
    line-height: 23px;
  }
`;

export const MainSectionDescription = styled.span`
  padding-bottom: 70px;
  display: inline-block;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    padding-bottom: 30px;
  }
`;

export const SocialMediaContainer = styled.div`
  padding-block: 80px;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    padding-block: 40px;
  }
`;

export const CheckboxLabel = styled.p`
  padding-top: 80px;
  margin-bottom: -80px;
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.DARK_GREY};

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    padding-top: 36px;
    margin-bottom: -36px;
  }
`;

export const ImageCloud = styled.img`
  position: absolute;
  top: -70px;
  left: -150px;
  width: 450px;
  height: auto;
  transform: scaleX(-1) rotate(-70deg);

  @media (max-width: ${BREAKPOINTS.DESKTOP}px) {
    width: 400px;
    left: -210px;
  }

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    width: 300px;
    transform: scaleY(-1) rotate(10deg);
    left: auto;
    top: -150px;
    right: 20px;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    width: 250px;
  }
`;

export const ImageOrange = styled.img`
  position: absolute;
  top: -170px;
  right: -100px;
  width: 400px;
  height: auto;
  transform: scaleX(-1) rotate(-30deg);

  @media (max-width: ${BREAKPOINTS.DESKTOP}px) {
    width: 350px;
    right: -150px;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    width: 250px;
    top: -120px;
    right: -110px;
  }
`;

export const ImageDotted = styled.img`
  position: absolute;
  bottom: 40px;
  left: -90px;
  width: 400px;
  height: auto;
  transform: scaleY(-1) rotate(90deg);

  @media (max-width: ${BREAKPOINTS.DESKTOP}px) {
    width: 300px;
    bottom: 100px;
  }

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    transform: scaleX(-1) rotate(100deg);
    width: 220px;
    right: auto;
    left: 20px;
    top: -90px;
    bottom: auto;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    width: 180px;
  }
`;

export const ImageYellow = styled.img`
  position: absolute;
  top: -60px;
  right: -140px;
  width: 600px;
  height: auto;
  transform: scaleY(-1) scaleX(-1);

  @media (max-width: ${BREAKPOINTS.DESKTOP}px) {
    width: 500px;
  }

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    top: -150px;
    right: -270px;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    width: 400px;
    top: -150px;
    right: -230px;
  }
`;
