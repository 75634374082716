import styled from 'styled-components';
import { Link } from 'gatsby';
import { BREAKPOINTS, COLORS } from '../../../utils/theme';

export const DialogContainer = styled.div`
  display: flex;
  min-height: 100%;
  align-items: center;
  justify-content: center;
`;

export const DialogPanel = styled.div`
  width: 100%;
  max-width: 600px;
  height: ${(props) => (props.type === 'termsOfUse' ? '466px' : 'auto')};
  overflow: auto;
  background: ${COLORS.WHITE};
  position: relative;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 90px 0px #2b2b2b33;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    height: 100vh;
    max-width: unset;
    border-radius: unset;
  }
`;

export const Button = styled.button`
  padding: 11px 24px;
  font-size: 14px;
  line-height: 24px;
  border-radius: 999px;
`;

export const InternalLink = styled(Link)`
  padding: 11px 24px;
  font-size: 14px;
  line-height: 24px;
  border-radius: 999px;
  text-decoration: none;
`;

export const ButtonsContainer = styled.div`
  padding: 24px;
  position: sticky;
  bottom: 0;
  background: ${COLORS.WHITE};

  ${(props) => {
    if (props.type === 'termsOfUse') {
      return `border-top: 1px solid ${COLORS.BLACK};`;
    } else {
      return `margin: 0 auto;`;
    }
  }}
`;

export const PrivacyContainer = styled.div`
  padding-inline: 24px;
  flex: 1 1 auto;
  padding-bottom: 10px;
`;

export const Content = styled.p`
  margin: 0;
  padding-bottom: 10px;
  line-height: 24px;
`;

export const ContentTitle = styled.h2`
  font-size: 16px;
  font-weight: 700;
  margin: 0;
`;

export const ModalTitle = styled.h2`
  font-size: 24px;
  line-height: 32px;
  margin: 0;
  font-weight: 500;

  ${(props) => {
    if (props.type === 'declined') {
      return `text-align: center;`;
    }
  }}
`;

export const ModalTitleContainer = styled.div`
  padding: 24px;
  position: sticky;
  top: 0;
  background: ${COLORS.WHITE};
`;

export const GlobalContainer = styled.div`
  position: fixed;
  inset: 0px;
  overflow-y: auto;
`;
