import React from 'react';
import * as Styled from './styles';
import ErrorMessage from '../ErrorMessage';

const ConsentForm = ({ watch, register, errors, canSocialMediaType, isOrganization }) => {
  const socialMediaConsent = watch('socialMediaConsent');

  return (
    <Styled.Fieldset>
      <legend className="sr-only">Consent to be featured on CAN’s social channels</legend>
      <div>
        <Styled.Input
          type="radio"
          id="yes"
          name="social-media"
          value="Yes"
          {...register('socialMediaConsent')}
        />
        <Styled.Label htmlFor="yes">
          Yes, {isOrganization ? 'we' : 'I'} would like {isOrganization ? 'our' : 'my'} profile and
          activities to be shared on CAN’s social media!
        </Styled.Label>
      </div>

      <div>
        <Styled.Input
          type="radio"
          id="custom"
          name="social-media"
          value="Custom"
          {...register('socialMediaConsent')}
        />
        <Styled.Label htmlFor="custom">
          Please let {isOrganization ? 'us' : 'me'} select on which channels{' '}
          {isOrganization ? 'our' : 'my'} content will be shared.
        </Styled.Label>

        {canSocialMediaType?.data?.length ? (
          <fieldset style={{ border: 0, marginLeft: '25px' }}>
            <legend className="sr-only">
              Channels on which channels {isOrganization ? 'our' : 'my'} content will be shared.
            </legend>

            {canSocialMediaType.data?.map((sm) => {
              return (
                <Styled.SocialMediaContainer>
                  <Styled.Input
                    name="social-media-custom"
                    id={sm.attributes.slug}
                    type="checkbox"
                    value={sm.id}
                    disabled={socialMediaConsent !== 'Custom'}
                    {...register('socialMediaConsentCustomSelection')}
                  />
                  <label htmlFor={sm.attributes.slug} style={{ opacity: 0.8 }}>
                    {sm.attributes.name}
                  </label>
                </Styled.SocialMediaContainer>
              );
            })}

            {errors?.['socialMediaConsentCustomSelection']?.message && (
              <ErrorMessage error={errors?.['socialMediaConsentCustomSelection']?.message} />
            )}
          </fieldset>
        ) : null}
      </div>

      <div>
        <Styled.Input
          type="radio"
          id="no"
          name="social-media"
          value="No"
          {...register('socialMediaConsent')}
        />
        <Styled.Label htmlFor="no">
          No, don't share {isOrganization ? 'our' : 'my'} information.
        </Styled.Label>
      </div>

      {errors?.['socialMediaConsent']?.message && (
        <ErrorMessage error={errors?.['socialMediaConsent']?.message} />
      )}
    </Styled.Fieldset>
  );
};

export default ConsentForm;
