import React from 'react';

const LoadingSpinner = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <p className="sr-only">Loading</p>
      <div className="spinner"></div>
    </div>
  );
};

export default LoadingSpinner;
