import styled from 'styled-components';

import { Link } from '@reach/router';
import { newButtonStyles } from '../../NewButton';
import { COLORS, BREAKPOINTS } from '../../../utils/theme';

export const Label = styled.p`
  margin: 0px;
  color: ${COLORS.DARK_GREY};
  font-size: 14px;
  opacity: 0.9;
  letter-spacing: 2%;
  padding-bottom: 6px;
`;

export const Description = styled.p`
  margin: 0px;
  color: ${COLORS.BLACK};
  font-size: 14px;
  letter-spacing: -0.16px;
  max-width: 82ch;
  padding-bottom: 24px;
  overflow-wrap: break-word;
`;

export const ExternalLink = styled.a`
  opacity: 0.9;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 2%;
  color: ${COLORS.BLUE};
  transition: opacity 0.25s linear;
  max-width: 35ch;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-decoration: none;

  &:hover {
    opacity: 1;
    text-decoration: underline;
  }
`;

export const ProfileContainerWrapper = styled.div`
  box-sizing: border-box;
  background: ${COLORS.BLUE};
  padding: 16px;
  padding-block: 120px;

  box-shadow: 0px 0px 90px 0px #2b2b2b33;

  position: relative;
  overflow: hidden;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    padding-block: 16px;
  }
`;

export const ProfileContainer = styled.div`
  box-sizing: border-box;

  max-width: 1200px;
  width: 100%;
  margin: 0 auto !important;
  background: ${COLORS.WHITE};
  padding: 24px;
  position: relative;
  z-index: 4;

  @media (min-width: ${BREAKPOINTS.PHABLET}px) {
    padding: 40px;
  }

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    padding: 60px;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: 100px;
  }

  & > div > h2,
  & > div > div > h2 {
    padding-bottom: 56px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const UserDetails = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 50px;
  padding-top: 30px;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    flex-direction: column-reverse;
    max-width: unset;
    gap: 0px;
    padding-top: 50px;
  }
`;

export const ImageProfileContainer = styled.div`
  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    display: flex;
    justify-content: center;
  }
`;

export const ImageProfile = styled.img`
  object-fit: cover;
  aspect-ratio: 1 / 1;
  max-width: 300px;
  max-height: 300px;
  border-radius: 4px;
  transform: rotate(-5.44deg);
  width: 100%;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    max-width: 200px;
    max-height: 200px;
    margin-bottom: 64px;
  }

  &[data-image-contain-size='true'] {
    background: white;
    outline: 2px solid ${COLORS.BLUE};
    object-fit: contain;
  }
`;

export const WorkContainer = styled.div`
  display: flex;
  column-gap: 60px;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    display: grid;
    row-gap: 60px;

    grid-template-columns: 1fr;

    & > div:first-child {
      order: 2;
    }

    & > div:nth-child(2) {
      order: 3;
    }

    & > div:last-child {
      order: 1;
    }
  }
`;

export const WorkImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -25px;
  position: relative;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    align-items: center;
  }
`;

export const FullName = styled.p`
  font-size: 72px;
  margin: 0px;
  padding-bottom: 24px;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    font-size: 40px;
  }
`;

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > a:last-child {
    padding-bottom: 24px;
  }

  & > a + a {
    margin-top: 4px;
  }
`;

export const ImageOrange = styled.img`
  position: absolute;
  top: -300px;
  right: -300px;
  width: 600px;
  height: auto;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    width: 500px;
    top: -240px;
    right: -240px;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    width: 400px;
    top: -200px;
    right: -200px;
  }
`;

export const ImageDottedBlue = styled.img`
  position: absolute;
  top: 400px;
  left: -100px;
  width: 400px;
  height: auto;
  transform: scaleY(-1);

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    top: auto;
    bottom: -100px;
  }
`;

export const ImageDottedBlueNewsletter = styled.img`
  position: absolute;
  top: 120px;
  left: -70px;
  width: 400px;
  height: auto;
  transform: scale(-1);

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    top: auto;
    bottom: -100px;
  }
`;

export const ImageYellow = styled.img`
  position: absolute;
  bottom: 0;
  right: -100px;
  width: 450px;
  height: auto;
  transform: scaleX(-1) rotate(20deg);

  @media (max-width: ${BREAKPOINTS.DESKTOP}px) {
    width: 400px;
    right: -140px;
  }

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    transform: scaleX(-1) rotate(-45deg);
    bottom: auto;
    width: 300px;
    top: -100px;
    right: -100px;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    width: 250px;
    top: -80px;
    right: -80px;
  }
`;

export const ConsentFooter = styled.p`
  color: ${COLORS.DARK_GREY};
  opacity: 0.8;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    font-size: 14px;
    text-align: left;
  }
`;

export const UserImageLines = styled.img`
  position: absolute;
  width: 150px;
  height: auto;
  pointer-events: none;

  ${(props) => {
    if (props.side === 'left') {
      return `
      top: 250px;
      left: -60px;
      z-index: -1;
      transform: scale(-1);
      `;
    } else {
      return `
      top: -130px;
      right: -70px;
      z-index: 2;
      `;
    }
  }}

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    ${(props) => {
      if (props.side === 'left') {
        return `
        top: 140px;
        left: -60px;
      `;
      } else {
        return `
        width: 100px;
        top: -110px;
        right: -50px;
      `;
      }
    }}
  }
`;

export const ThreeLinesImage = styled.img`
  position: absolute;
  height: auto;
  pointer-events: none;

  ${(props) => {
    if (props.side === 'left') {
      return `
      bottom: 0px;
      right: calc(50% + 50px);
      transform: scale(-1) rotate(-35deg);
      z-index: -1;
      width: 160px;
      `;
    } else {
      return `
      top: -50px;
      left: 50%;
      z-index: 2;
      width: 130px;

    `;
    }
  }}
`;

export const ImagesOfWork = styled.img`
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 4px;
  border: 2px solid ${COLORS.WHITE};

  ${(props) => {
    if (props.idx === 0) {
      return `
      transform: rotate(-5.44deg);
      marginRight: 90px;
      `;
    } else if (props.idx === 1) {
      return `
      transform: rotate(6.88deg);
      marginLeft: 90px;
       marginTop: -20px;
      `;
    } else if (props.idx === 2) {
      return `
      transform: rotate(-4.77deg);
      marginRight: 90px;
      marginTop: -30px;
      `;
    }
  }}
`;

export const InternalLink = styled(Link).attrs(() => ({
  variant: 'secondary',
  size: 'small',
  border: true,
}))`
  text-decoration: none;
  ${newButtonStyles}
`;
