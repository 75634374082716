import React from 'react';

import * as Styled from './styles';

const FormSection = ({ title, children }) => {
  return (
    <Styled.RegistrationSection>
      {title && <Styled.SectionFormTitle>{title}</Styled.SectionFormTitle>}

      {children}
    </Styled.RegistrationSection>
  );
};

export default FormSection;
