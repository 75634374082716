import React, { useState, useEffect } from 'react';
import { COLORS } from '../../../utils/theme';
import { Dialog } from '@headlessui/react';

import { getProfileDescription } from '../../../lib/utils';

import StepHeader from '../StepHeader';
import FormSection from '../FormSection';
import ProfileCard from '../../NewNetwork/ProfileCard';
import ErrorMessage from '../ErrorMessage';

import qs from 'qs';
import { contentFetch } from '../../../lib/content-fetcher';

import * as Styled from './styles';

function Popup({
  isOpen,
  setIsOpen,
  platformUsers,
  errors,
  id,
  watchLinkedToUsers,
  setSelectedUsers,
  modifiedSelectedUsers,
  setModifiedSelectedUsers,
}) {
  const [nameSearch, setNameSearch] = useState();

  useEffect(() => {
    setModifiedSelectedUsers(watchLinkedToUsers);
  }, []);

  const usersSearchFiltered =
    platformUsers?.filter(
      (user) =>
        user.id &&
        (nameSearch?.length
          ? user.fullName?.toLowerCase()?.includes(nameSearch.toLowerCase())
          : true),
    ) || [];

  const onKeyDown = (e) => {
    if (e.key === 'Enter') e.preventDefault();
  };

  const onChangeUserSelection = (e, userId) => {
    const isChecked = e.target.checked;
    setModifiedSelectedUsers((prev) => {
      if (isChecked) {
        return [...(prev || []), String(userId)];
      }
      return prev?.filter((el) => String(el) !== String(userId)) || [];
    });
  };

  return (
    <Dialog
      as="div"
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
        setModifiedSelectedUsers(watchLinkedToUsers ? watchLinkedToUsers : []);
      }}
      style={{ position: 'relative', zIndex: '9999' }}
    >
      <Styled.PopupGlobalContainer>
        <Styled.DialogContainer>
          <Styled.DialogPanel as={Dialog.Panel}>
            <Styled.InputContainer>
              <Styled.SvgIcon xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none">
                <circle cx="9" cy="5.5" r="4.4" stroke="currentColor" stroke-width="1.2" />
                <path stroke="currentColor" stroke-width="1.2" d="m5.42 8.92-4.24 4.25" />
              </Styled.SvgIcon>
              <Styled.InputText
                placeholder="Type to search"
                type="text"
                id="search"
                onKeyDown={onKeyDown}
                onChange={(e) => setNameSearch(e.target.value)}
              />
            </Styled.InputContainer>

            <div
              style={{
                paddingInline: '12px',
                flex: '1 1 auto',
                paddingBottom: '10px',
                background: COLORS.WHITE,
              }}
            >
              <Styled.ListOptions>
                {usersSearchFiltered.length
                  ? usersSearchFiltered.map((user) => (
                      <>
                        <Styled.Option key={user.id}>
                          <ProfileCard
                            key={user.id}
                            userData={user}
                            desktopImageSize="S"
                            mobileImageSize="S"
                            hideUserInfo={true}
                            widthAuto={true}
                            imageUrl={user?.image?.formats?.thumbnail?.url || user?.image?.url}
                            width={user?.image?.formats?.thumbnail?.width || user?.image?.width}
                            height={user?.image?.formats?.thumbnail?.height || user?.image?.height}
                          ></ProfileCard>
                          <Styled.UserInfo>
                            <Styled.InputCheckbox
                              type="checkbox"
                              id={user.id}
                              name={user.id}
                              value={user.id}
                              defaultChecked={modifiedSelectedUsers?.includes(String(user.id))}
                              onChange={(e) => onChangeUserSelection(e, user.id)}
                            />
                            <Styled.Label htmlFor={user.id}>{user.fullName}</Styled.Label>
                            <Styled.UserType>{getProfileDescription(user)}</Styled.UserType>
                            <div>
                              <Styled.Checkmark src="/images/check.png" alt=""></Styled.Checkmark>
                            </div>
                          </Styled.UserInfo>
                        </Styled.Option>
                      </>
                    ))
                  : null}
              </Styled.ListOptions>
              {errors?.[id]?.message && <ErrorMessage error={errors?.[id]?.message} />}
            </div>
            <Styled.SelectUsersContainer>
              <Styled.SelectUsers
                onClick={() => {
                  setSelectedUsers(modifiedSelectedUsers);
                  setIsOpen(false);
                }}
                watchLinkedToUsers={watchLinkedToUsers?.length}
              >
                Endorse {modifiedSelectedUsers?.length ? modifiedSelectedUsers?.length : null}{' '}
                Members
              </Styled.SelectUsers>
            </Styled.SelectUsersContainer>
          </Styled.DialogPanel>
        </Styled.DialogContainer>
      </Styled.PopupGlobalContainer>
    </Dialog>
  );
}

const ThirdStep = ({
  register,
  watch,
  getValues,
  errors,
  setValue,
  step,
  isOrganization,
  userInfo,
}) => {
  const [platformUsers, setPlatformUsers] = useState();
  const [fetchSelectedUsers, setFetchSelectedUsers] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const [modifiedSelectedUsers, setModifiedSelectedUsers] = useState([]);

  const id = 'linked_to_users';
  const watchLinkedToUsers = watch(id);
  const linkedUsers = watchLinkedToUsers ? watchLinkedToUsers?.map((id) => Number(id)) : null;

  const filteredPlatformUsers = platformUsers?.filter(({ id }) => id !== userInfo?.id);

  useEffect(() => {
    const query = qs.stringify(
      {
        filters: {
          member: true,
          publishedAt: {
            $notNull: true,
          },
        },
        populate: {
          image: {
            fields: ['url', 'width', 'height'],
            populate: {
              formats: {
                populate: '*',
              },
            },
          },
          typeOfMember: {
            fields: ['label'],
          },
          individualProfiles: {
            fields: ['name'],
          },
          organizationType: {
            fields: ['name'],
          },
        },
        fields: ['fullName', 'color', 'imageContainSize'],
        sort: ['sortName:asc'],
        // limit: 10,
      },
      {
        encodeValuesOnly: true,
      },
    );

    contentFetch({
      pathname: `/users?${query}`,
      setState: setPlatformUsers,
    });
  }, []);

  useEffect(() => {
    if (!linkedUsers?.length) {
      setFetchSelectedUsers([]);
    } else {
      const query = qs.stringify(
        {
          filters: {
            member: true,
            publishedAt: {
              $notNull: true,
            },
            id: {
              $in: linkedUsers,
            },
          },
          populate: {
            image: {
              fields: ['url', 'width', 'height', 'formats'],
            },
            typeOfMember: {
              fields: ['label'],
            },
            individualProfiles: {
              fields: ['name'],
            },
            organizationType: {
              fields: ['name'],
            },
          },
          fields: ['fullName', 'color', 'imageContainSize'],
          sort: ['sortName:asc'],
          // limit: 10,
        },
        {
          encodeValuesOnly: true,
        },
      );

      contentFetch({
        pathname: `/users?${query}`,
        setState: setFetchSelectedUsers,
      });
    }
  }, [watchLinkedToUsers]);

  return (
    <div>
      <StepHeader
        title="Explore <span>your</span> <span>network</span>"
        bgColor={COLORS.RED}
        textColor={COLORS.WHITE}
        step={step}
      ></StepHeader>

      <FormSection title="Endorse members" style={{ position: 'relative' }}>
        <Styled.Text>
          Select CAN members you know and whose work resonates with yours. This will be displayed on
          your profile.
        </Styled.Text>

        {!watchLinkedToUsers?.length ? (
          <Styled.NoUsersSelectedButton type="button" onClick={(e) => setIsOpen(true)}>
            <Styled.BlueLinesImage
              src="/images/blue-lines.png"
              alt=""
              width="528"
              height="224"
            ></Styled.BlueLinesImage>
          </Styled.NoUsersSelectedButton>
        ) : null}

        {fetchSelectedUsers?.length ? (
          <Styled.SelectedUsersContainer>
            <Styled.SelectedUsersListOptions>
              {fetchSelectedUsers?.map((user, idx) => {
                return (
                  <Styled.SelectedUsersOption key={idx}>
                    <ProfileCard
                      key={user.id}
                      userData={user}
                      hideUserInfo={true}
                      desktopImageSize="L"
                      mobileImageSize="M"
                      imageUrl={user?.image?.formats?.small?.url || user?.image?.url}
                      width={user?.image?.formats?.small?.width || user?.image?.width}
                      height={user?.image?.formats?.small?.height || user?.image?.height}
                    ></ProfileCard>
                    <Styled.FullName style={{ paddingBottom: '0.25rem' }}>
                      {user?.fullName}
                    </Styled.FullName>
                    <p style={{ margin: '0', fontSize: '14px' }}>{getProfileDescription(user)}</p>
                  </Styled.SelectedUsersOption>
                );
              })}
            </Styled.SelectedUsersListOptions>
          </Styled.SelectedUsersContainer>
        ) : null}

        <Styled.AddButton
          onClick={(e) => {
            e.preventDefault();
            setIsOpen(true);
          }}
        >
          <Styled.AddButtonText watchLinkedToUsers={watchLinkedToUsers?.length}>
            Add member
          </Styled.AddButtonText>
          <Styled.PlusImage
            src={
              watchLinkedToUsers?.length ? '/images/icon-plus-blue.png' : '/images/icon-plus.png'
            }
            alt=""
            width="77"
            height="77"
          ></Styled.PlusImage>
        </Styled.AddButton>

        {isOpen && (
          <Popup
            setIsOpen={setIsOpen}
            platformUsers={filteredPlatformUsers}
            errors={errors}
            id={id}
            isOpen={isOpen}
            watchLinkedToUsers={watchLinkedToUsers}
            setSelectedUsers={(values) => setValue(id, values)}
            modifiedSelectedUsers={modifiedSelectedUsers}
            setModifiedSelectedUsers={setModifiedSelectedUsers}
          ></Popup>
        )}

        <div style={{ position: 'absolute', inset: 0, overflow: 'hidden', pointerEvents: 'none' }}>
          <Styled.ImageYellow src="/images/yellow.png" alt="1342" width="1285"></Styled.ImageYellow>
        </div>
      </FormSection>
    </div>
  );
};

export default ThirdStep;
