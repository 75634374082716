import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from '../../../utils/theme';

export const AddButton = styled.button`
  background: none;

  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.2px;
  text-align: left;

  border-style: solid;
  border-bottom-width: 5px;

  border-image: url('/images/trace-gray.svg') 0 0 20 0 stretch stretch;
  display: flex;
  align-items: center;
  margin: 0 auto;

  &:focus-visible {
    border: 2px solid ${COLORS.BLACK};
    border-radius: 12px;
  }
`;

export const DialogPanel = styled.div`
  width: 100%;
  max-width: 480px;
  height: 596px;
  overflow: auto;
  background: white;
  position: relative;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 90px 0px #2b2b2b33;

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    height: 100vh;
    height: 100svh;
    border-radius: unset;
  }
`;

export const DialogContainer = styled.div`
  display: flex;
  min-height: 100%;
  align-items: center;
  justify-content: center;
`;

export const SelectUsersContainer = styled.div`
  position: sticky;
  bottom: 0;
  padding-block: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  background: ${COLORS.WHITE};
  border-top: 1px solid ${COLORS.BLACK};
  z-index: 6;
`;

export const SelectUsers = styled.button`
  padding: 11px 24px;
  border-radius: 40px;
  font-size: 14px;
  border: none;
  background-color: ${COLORS.BLUE};
  color: ${COLORS.WHITE};
`;

export const ListOptions = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  padding-block: 2px;

  & > * + * {
    margin-top: 8px;
  }
`;

export const InputText = styled.input`
  border-radius: 4px;
  background: ${COLORS.WHITE};

  border: 1px solid ${COLORS.BLACK};
  box-sizing: border-box;

  width: 100%;
  font-weight: 500;
  padding-block: 8px;
  padding-left: 30px;
  padding-right: 10px;
  font-size: 16px;

  position: relative;
`;

export const SvgIcon = styled.svg`
  position: absolute;
  top: 24px;
  left: 22px;
  z-index: 999;
`;

export const InputContainer = styled.div`
  position: sticky;
  top: 0px;
  background-color: ${COLORS.WHITE};
  padding: 12px;
  z-index: 6;
`;

export const Option = styled.li`
  display: flex;
  align-items: center;
  position: relative;

  &:has(:focus-visible):focus-within {
    outline: 2px solid ${COLORS.BLACK};
    border-radius: 12px;
  }
`;

export const InputCheckbox = styled.input`
  position: absolute;
  inset: 0;
  opacity: 0;
  cursor: pointer;
  margin-inline: 12px;

  & ~ div {
    width: 35px;
    height: 45px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);

    background-image: url('/images/checkbox-circle.png');
    background-size: cover;
    background-position: center;

    pointer-events: none;
  }

  &:checked ~ div {
    background-image: url('/images/checkbox-circle-black.png');
  }

  & ~ div > img {
    display: none;
  }

  &:checked ~ div > img {
    display: block;
  }
`;

export const Label = styled.label`
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;

  &:after {
    content: '';
    position: absolute;
    inset: 0px;
    z-index: 5;
  }
`;

export const Checkmark = styled.img`
  width: 60px;
  position: absolute;
  right: -15px;
  bottom: 4px;
`;

export const UserType = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 20px;
`;

export const SelectedUsersListOptions = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  padding-inline: 24px;
  justify-content: center;
`;

export const NoUsersSelectedButton = styled.button`
  position: relative;
  margin: 63px auto;
  height: 180px;
  width: 180px;
  border-radius: 26px;
  background: transparent;
  border: none;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='26' ry='26' stroke='black' stroke-width='2.5' stroke-dasharray='6%2c10' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    margin: 48px auto;
  }

  &:focus-visible {
    outline: 2px solid ${COLORS.BLACK};
    border-radius: 26px;
    background-image: none;
  }
`;

export const AddButtonText = styled.span`
  color: ${(props) => (props.watchLinkedToUsers ? COLORS.BLUE : COLORS.DARK_GREY)};
  opacity: ${(props) => (props.watchLinkedToUsers ? '1' : '0.5')};
`;

export const Text = styled.p`
  font-size: 34px;
  line-height: 40px;
  max-width: 758px;
  margin: 0 auto;
  padding-top: 30px;
  padding-inline: 24px;

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
  }
`;

export const SelectedUsersContainer = styled.div`
  padding-block: 63px;

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    padding-block: 48px;
  }
`;

export const FullName = styled.p`
  margin: 0;
  line-height: 1.2;
  font-size: 24px;
  padding-block: 8px;
`;

export const SelectedUsersOption = styled.li`
  color: ${COLORS.BLACK};
  text-align: left;
  font-weight: 500;
  max-width: 180px;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    max-width: 120px;
  }
`;

export const PlusImage = styled.img`
  width: 38px;
  height: 38px;
  padding-left: 16px;
  padding-right: 8px;
`;

export const BlueLinesImage = styled.img`
  width: 264px;
  height: auto;
  position: absolute;
  top: 20px;
  left: -40px;
`;

export const UserInfo = styled.div`
  max-width: 280px;

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    max-width: 190px;
  }
`;

export const UserImage = styled.img`
  width: 180px;
  height: 180px;
  border-radius: 16px;
  object-fit: cover;

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    width: 120px;
    height: 120px;
  }
`;

export const PopupGlobalContainer = styled.div`
  position: fixed;
  inset: 0px;
  overflow-y: auto;
  background: ${COLORS.BLACK}70;
  padding-block: 12px;

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    padding-block: unset;
  }
`;

export const ImageYellow = styled.img`
  position: absolute;
  top: -100px;
  right: -280px;
  width: 600px;
  height: auto;
  transform: scaleY(-1) scaleX(-1);

  @media (max-width: ${BREAKPOINTS.DESKTOP}px) {
    width: 500px;
  }

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    top: -150px;
    right: -300px;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    width: 400px;
    top: -150px;
    right: -230px;
  }
`;
