import styled from 'styled-components';
import { COLORS, BREAKPOINTS } from '../../../utils/theme';

export const NavigationFooterGlobalContainer = styled.div``;

export const ErrorMessage = styled.p`
  margin: 0;
  background: ${COLORS.LIGHT_RED};
  text-align: center;
  padding: 0.75rem 1rem;
  color: ${COLORS.WHITE};
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    font-size: 14px;
  }
`;

export const NavigationFooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ isStepValid }) => (isStepValid ? COLORS.BLUE : COLORS.BLACK)};
  color: ${COLORS.WHITE};
  padding: 0.75rem 1rem;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    flex-direction: column;

    ${(props) => {
      if (props.isStepValid) {
        return `padding: 0.75rem;`;
      }
    }}
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

export const Text = styled.p`
  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    margin-top: 0;
    text-align: center;
  }
`;
