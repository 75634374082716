import React from 'react';
import * as Styled from './styles';

const StepHeader = ({
  title,
  subtitle,
  bgColor,
  textColor,
  smallPadding,
  smallDescription,
  finalStep,
  hasUserId,
  step,
}) => {
  return (
    <Styled.HeaderContainer finalStep={finalStep} smallPadding={smallPadding} bgColor={bgColor}>
      <Styled.HeaderTextWrapper>
        {title && (
          <Styled.HeaderTitle
            finalStep={finalStep}
            textColor={textColor}
            step={step}
            dangerouslySetInnerHTML={{ __html: title }}
          ></Styled.HeaderTitle>
        )}
        {subtitle && (
          <Styled.HeaderText
            finalStep={finalStep}
            smallDescription={smallDescription}
            textColor={textColor}
            step={step}
            dangerouslySetInnerHTML={{ __html: subtitle }}
          ></Styled.HeaderText>
        )}

        {finalStep && (
          <>
            <Styled.LinesLeft
              src="/images/lines.png"
              alt=""
              width="509"
              height="526"
              hasUserId={hasUserId}
            ></Styled.LinesLeft>
            <Styled.LinesRight
              src="/images/lines.png"
              alt=""
              width="509"
              height="526"
              hasUserId={hasUserId}
            ></Styled.LinesRight>
          </>
        )}
      </Styled.HeaderTextWrapper>
    </Styled.HeaderContainer>
  );
};
export default StepHeader;
