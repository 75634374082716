import React, { useState, useEffect, useRef } from 'react';

import { API_HOSTNAME } from '../../../utils/urls';
import ErrorMessage from '../ErrorMessage';

import * as Styled from './styles';
import { SHORT_INPUT_MAX_LENGTH } from '../../../utils/constants';

const fetchFiles = async (initialIds) => {
  const fetchedFiles = await Promise.allSettled(
    initialIds.map(async (fileId) => {
      try {
        const response = await fetch(API_HOSTNAME + `/upload/files/${fileId}`);
        if (!response.ok) {
          throw new Error(`Failed to fetch file with id ${fileId}`);
        }
        const fileData = await response.json();
        return {
          name: fileData.name,
          id: fileId,
          state: 'loaded',
          src: fileData.url,
        };
      } catch (error) {
        return {
          name: null,
          id: fileId,
          state: 'failed',
          src: null,
          error: error.message,
        };
      }
    }),
  );

  const successfulFiles = fetchedFiles
    .filter((result) => result.status === 'fulfilled')
    .map((result) => result.value);

  const failedFiles = fetchedFiles
    .filter((result) => result.status === 'rejected')
    .map((result) => result.reason);

  if (failedFiles.length > 0) {
    console.error('Some files failed to fetch:', failedFiles);
  }

  return successfulFiles;
};

const StepInputFile = ({
  label,
  id,
  userInfo,
  children,
  setValue,
  maxFiles = 1,
  initialIds,
  isOrganization,
  isMobile,
  showNumbers,
}) => {
  const [files, setFiles] = useState([]);
  const inputElRef = useRef();
  const [isDragging, setIsDragging] = useState(false);

  const [error, setError] = useState(null);

  const canUploadMoreFiles = files.length < maxFiles;

  useEffect(async () => {
    if (initialIds?.length) {
      const initialFilesData = await fetchFiles(initialIds);
      setFiles(initialFilesData);
    }
  }, [initialIds]);

  useEffect(() => {
    const filesWithIds = files.filter((file) => file.id).map((file) => file.id);
    setValue(id, filesWithIds);
  }, [files]);

  const clearInput = () => {
    inputElRef.current.value = '';
  };

  const focusInput = () => {
    inputElRef.current.focus();
  };

  const uploadFile = async (file) => {
    const isFileTypeOk = file.type.startsWith('image');
    if (!isFileTypeOk) {
      setError(`${file.name} is not image`);
      return;
    }

    const maxSize = 10 * 1024 * 1024; // 10MB in bytes
    const isFileSizeOk = file.size < maxSize;
    if (!isFileSizeOk) {
      setError(`${file.name} is too big`);
      return;
    }

    const tempId = Math.random().toString(36).substr(2, 9);

    const newFileName = file.name.slice(0, SHORT_INPUT_MAX_LENGTH);

    const newFile = {
      name: newFileName,
      tempId: tempId,
      state: 'loading',
      src: URL.createObjectURL(file),
    };
    setFiles((oldFiles) => [...oldFiles, newFile]);

    const formData = new FormData();
    formData.append('files', file);

    try {
      const response = await fetch(`${API_HOSTNAME}/upload`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        setError(`${newFileName} upload failed`);
        setFiles((oldFiles) =>
          oldFiles.map((file) => (file.tempId === tempId ? { ...file, state: 'error' } : file)),
        );

        throw new Error('File upload failed');
      }

      const data = await response.json();
      const uploadedFile = data[0];

      if (uploadedFile.id) {
        setError(null);
        setFiles((oldFiles) =>
          oldFiles.map((file) =>
            file.tempId === tempId
              ? { ...file, id: uploadedFile.id, tempId: null, state: 'loaded' }
              : file,
          ),
        );
      }
    } catch (error) {}
  };

  const uploadFiles = (files) => {
    setError(null);
    if (!canUploadMoreFiles) {
      return setError('Files are already uploaded');
    }
    if (files.length > maxFiles) {
      return setError("Can't upload more images than permitted");
    }
    for (var i = 0; i < files.length; i++) {
      uploadFile(files[i]);
    }
  };

  const onInputChange = (e) => {
    uploadFiles(e.currentTarget.files);
    clearInput();
    focusInput();
  };

  const onFileDeleteClick = async (fileId) => {
    setFiles((oldFiles) => oldFiles.filter((file) => file.id !== fileId));
  };

  const onDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const onDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const onDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    uploadFiles(e.dataTransfer.files);
  };
  return (
    <Styled.QuestionContainer>
      <Styled.DropZoneWithPreviewsContainer maxFiles={maxFiles}>
        {error && (
          <ErrorMessage
            marginTop="0px"
            marginBottom={files?.length && maxFiles === 1 ? '68px' : '16px'}
            error={error}
          />
        )}
        <div
          style={{
            position: 'absolute',
            inset: 0,
            zIndex: 10,
            pointerEvents: 'none',
          }}
        >
          <p className="sr-only">Files added</p>
          {files?.length ? (
            <Styled.PreviewList>
              {files.map((file, idx) => (
                <Styled.PreviewListItem maxFiles={maxFiles}>
                  <Styled.PreviewImage
                    data-image-contain-size={userInfo?.imageContainSize}
                    src={file.src}
                    alt={file.name}
                  />

                  {showNumbers && (
                    <Styled.PreviewImageId>
                      <span>{idx + 1}</span>
                    </Styled.PreviewImageId>
                  )}

                  <Styled.PreviewImageActionsAndStatusContainer>
                    {file.id && (
                      <Styled.PreviewImageDeleteBtn
                        type="button"
                        onClick={() => onFileDeleteClick(file.id)}
                      >
                        <span className="sr-only">Delete</span>
                        <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect
                            x="0.5"
                            y="0.5"
                            width="31"
                            height="31"
                            rx="15.5"
                            stroke="currentColor"
                          />
                          <path
                            d="M12.1061 12.0931L16.0129 16L12.1061 19.9069"
                            stroke="currentColor"
                            stroke-width="1.2"
                          />
                          <path
                            d="M19.9076 12.0931L16.0007 16L19.9076 19.9069"
                            stroke="currentColor"
                            stroke-width="1.2"
                          />
                        </svg>
                      </Styled.PreviewImageDeleteBtn>
                    )}
                    {file.state === 'loading' && (
                      <Styled.PreviewImageLoadingSpinner>
                        <span className="sr-only">Loading</span>
                      </Styled.PreviewImageLoadingSpinner>
                    )}
                  </Styled.PreviewImageActionsAndStatusContainer>
                </Styled.PreviewListItem>
              ))}
            </Styled.PreviewList>
          ) : null}
        </div>

        <Styled.DropZone
          className="dropzone"
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
        >
          <input
            className="sr-only"
            ref={inputElRef}
            type="file"
            id={id}
            name="files"
            multiple
            onChange={onInputChange}
          />
          {canUploadMoreFiles && (
            <Styled.DropZoneExplanation showAtBottom={files?.length > 0}>
              {id === 'imagesOfWork' && !files.length && (
                <p style={{ paddingInline: '30px', maxWidth: '40ch' }}>
                  We'll use these images in content promoting your work - share your best ones!
                </p>
              )}
              {!isMobile && (
                <>
                  Drag and drop your {isOrganization ? 'picture' : 'image'}
                  {files?.length <= 0 ? <br aria-hidden="true" /> : null} or{' '}
                </>
              )}
              <Styled.InputLabel
                htmlFor={id}
                style={{ textTransform: !isMobile ? 'none' : 'capitalize' }}
              >
                browse files
              </Styled.InputLabel>
            </Styled.DropZoneExplanation>
          )}
          <Styled.DraggingOverlay
            isDragging={isDragging}
            className="dragging-overlay"
          ></Styled.DraggingOverlay>
        </Styled.DropZone>
      </Styled.DropZoneWithPreviewsContainer>
      <Styled.InputFooter maxFiles={maxFiles}>{children}</Styled.InputFooter>
    </Styled.QuestionContainer>
  );
};

export default StepInputFile;
