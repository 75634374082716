import styled from 'styled-components';
import { COLORS, BREAKPOINTS } from '../../../utils/theme';

export const QuestionContainer = styled.div`
  text-align: center;
  margin-block: 12px;
  width: 100%;

  display: flex;
  flex-direction: ${(props) => (props.inputBeforeLabel ? 'column-reverse' : 'column')};

  ${(props) => {
    if (props.extraPadding) {
      return `padding-bottom: 24px;`;
    }
  }}

  @media (min-width: ${BREAKPOINTS.PHABLET}px) {
    flex-direction: ${(props) =>
      props.layout === 'always-column'
        ? props.inputBeforeLabel
          ? 'column-reverse'
          : 'column'
        : props.inputBeforeLabel
        ? 'row-reverse'
        : 'row'};
    align-items: center;
    justify-content: center;
    width: auto;
    margin-block: 35px;
  }
`;

export const QuestionDescription = styled.p``;

export const AnswersContainer = styled.div``;

export const SelectLabel = styled.label`
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: -0.2px;
  text-align: center;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    display: block;
    font-size: 24px;
    line-height: 32px;
    padding-block: 5px;
  }
`;

export const SelectOptions = styled.select`
  margin-top: 1rem;
  width: 100%;
  height: 3.3rem;
`;

export const QuestionOptions = styled.option``;

export const ComboboxOptions = styled.ul`
  position: absolute;
  top: 100%;
  right: 0;

  z-index: 25;
  width: 316px;
  max-height: 258px;
  overflow-y: auto;
  border: 1px solid ${COLORS.BLACK};
  border-radius: 4px;
  background-color: ${COLORS.WHITE};
  list-style: none;
  margin: 10px auto 0 auto;
  padding-block: 12px;
  padding-inline-start: 0px;

  & > li + li {
    margin-top: 4px;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    width: 100%;
  }
`;

export const ComboboxOption = styled.li`
  text-align: left;
  cursor: default;

  & > div[data-active='true'] {
    background: ${COLORS.LIGHT_GREY};
  }

  & > div[data-selected='true'] {
    font-weight: 600;
  }
`;

export const InputText = styled.input`
  border-radius: 4px;
  background: ${COLORS.LIGHT_GREY};

  border: 1px solid ${COLORS.BLACK};
  box-sizing: border-box;

  width: 100%;
  font-weight: 500;
  padding-block: 6px;
  padding-left: 30px;
  padding-right: 10px;

  margin-bottom: 12px;
  position: relative;
`;

export const Checkbox = styled.div`
  width: 14px;
  height: 14px;
  border-radius: ${(props) => (props.multiple ? '2px' : '999px')};
  border: 1.2px solid ${COLORS.DARK_GREY};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${COLORS.WHITE};

  &[data-selected='true'] {
    background: ${COLORS.BLUE};
  }
`;

export const OptionList = styled.div`
  display: flex;
  column-gap: 9px;
  align-items: center;
  padding-block: 4px;
  padding-inline: 12px;
`;

export const SvgIcon = styled.svg`
  position: absolute;
  top: 8px;
  left: 22px;
  z-index: 999;
`;

export const OptionContainer = styled.div`
  display: inline-block;
  position: relative;
  max-width: 52ch;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    width: 100%;
    max-width: unset;
  }
`;

export const SelectedOption = styled.span`
  appearance: none;
  border: none;
  background: none;

  box-sizing: border-box;
  border-color: transparent;

  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: -0.2px;
  text-align: left;
  padding-left: 10px;
  padding-right: 60px;
  margin-left: 16px;

  border-style: solid;
  border-bottom-width: 5px;
  color: ${COLORS.DARK_GREY};

  border-image: url('/images/trace-gray.svg') 0 0 20 0 stretch stretch;

  ${(props) => {
    if (props.value) {
      return `
      color: ${COLORS.BLUE};
      border-image: url('/images/trace-blue.svg') 0 0 20 0 stretch stretch;
      `;
    }
  }}

  ${(props) => {
    if (props.disabled) {
      return `
      padding-right: 0;
      `;
    }
  }}

  ${(props) => {
    if (props.minWidth) {
      return `
      display: inline-block;
      min-width: 240px;
      `;
    }
  }}

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    width: 100%;
    display: inline-block;
    font-size: 24px;
    line-height: 32px;
    padding-left: unset;
    margin-left: unset;
  }
`;

export const Dropdown = styled.button`
  &:focus-visible {
    outline: 2px solid ${COLORS.BLUE};
  }

  &:after {
    content: '';
    position: absolute;
    inset: 0;
    z-index: 20;
  }
`;

export const ArrowImage = styled.img`
  width: 33px;
  height: 26px;
  position: relative;
  right: 55px;
  pointer-events: none;
`;

export const ArrowDownImage = styled.div`
  width: 33px;
  height: 26px;
  display: inline-block;
  position: absolute;
  right: 12px;
  bottom: 8px;
  pointer-events: none;

  &:after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    background-image: url('/images/arrow-down.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
`;
