import styled from 'styled-components';

import { COLORS } from '../../../utils/theme';

export const Container = styled.div`
  display: inline-flex;
  align-items: flex-start;
  text-align: left;
`;

export const InputCheckbox = styled.input`
  width: 1rem;
  height: 1rem;
  accent-color: ${COLORS.BLUE};
`;

export const Label = styled.label`
  max-width: 405px;
  display: inline-block;
  padding-left: 0.75rem;
  font-size: 16px;
  color: ${COLORS.DARK_GREY};
  font-weight: 400;
  opacity: 0.9;
`;
