import styled from 'styled-components';
import { COLORS, BREAKPOINTS } from '../../../utils/theme';

export const Container = styled.div`
  position: fixed;
  padding-top: 3.25rem;
  top: 0;
  left: 0;
  right: 0;
  z-index: 30;
  background-color: ${COLORS.WHITE};
  border-bottom: 1px solid ${COLORS.WHITE};

  @media (min-width: ${BREAKPOINTS.PHABLET}px) {
    padding-top: 3.25rem;
  }
`;

export const Header = styled.div`
  display: grid;
  ${({ totalSteps }) => `grid-template-columns: repeat(${totalSteps}, 1fr);`}
`;

export const SectionTitle = styled.p`
  position: relative;
  margin-left: 1.25rem;
  transition: colors 0.5s cubic-bezier(0.16, 1, 0.3, 1), transform 1s cubic-bezier(0.16, 1, 0.3, 1);

  ${({ isCurrent }) => isCurrent && `color: ${COLORS.BLUE};`};
  ${({ isPreviousStep }) => isPreviousStep && `transform: translateX(20px)`};

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
  }

  & svg {
    animation: fadeIn 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  }
`;

export const ProgressBarWrapper = styled.div`
  position: relative;
  height: 0.25rem;
  width: 100%;
  background-color: ${COLORS.LIGHT_GREY};
  transform: translateY(1px);
`;

export const AnimatedProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  /* width: 100%; */
  transform-origin: left;
  background-color: ${COLORS.BLUE};
  ${({ scaleX }) => `width: calc(${scaleX * 100}vw + 1.25rem)`};

  transition: width 0.7s cubic-bezier(0.22, 1, 0.36, 1);
`;

export const ProgressNameMobile = styled.span`
  color: ${(props) => (props.isPreviousStep ? COLORS.BLUE : COLORS.BLACK)};
  @media (min-width: ${BREAKPOINTS.PHABLET}px) {
    display: none;
  }
`;
export const ProgressNameDesktop = styled.span`
  display: none;
  @media (min-width: ${BREAKPOINTS.PHABLET}px) {
    display: initial;
  }
`;

export const SvgTick = styled.svg`
  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    display: none;
  }
`;
