import React from 'react';
import * as Styled from './styles';

const shortNameSteps = ['You', 'Work', 'Network', 'Confirm'];
const steps = ['About You', 'About your work', 'Your network', 'Confirmation'];

const ProgressBar = ({ step, totalSteps }) => {
  return (
    <Styled.Container>
      <Styled.Header totalSteps={totalSteps}>
        {steps.map((stepName, idx) => {
          const isPreviousStep = step > idx;
          return (
            <Styled.SectionTitle key={idx} isCurrent={step === idx} isPreviousStep={isPreviousStep}>
              <div
                style={{ position: 'absolute', left: '-0.25rem', transform: 'translateX(-100%)' }}
              >
                {isPreviousStep && (
                  <Styled.SvgTick
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path
                      d="M18.0143 7.99963L10.1074 15.9065L6.20057 11.9996"
                      stroke="currentColor"
                      strokeWidth="1.2"
                    />
                  </Styled.SvgTick>
                )}
              </div>
              <Styled.ProgressNameMobile isPreviousStep={isPreviousStep}>
                {shortNameSteps[idx]}
              </Styled.ProgressNameMobile>
              <Styled.ProgressNameDesktop>{stepName}</Styled.ProgressNameDesktop>
            </Styled.SectionTitle>
          );
        })}
      </Styled.Header>
      <Styled.ProgressBarWrapper>
        <Styled.AnimatedProgressBar scaleX={step / totalSteps}></Styled.AnimatedProgressBar>
      </Styled.ProgressBarWrapper>
    </Styled.Container>
  );
};

export default ProgressBar;
