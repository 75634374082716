import styled from 'styled-components';

import { BREAKPOINTS, COLORS } from '../../../utils/theme';

const backgroundImages = [
  '/images/orange-button.png',
  '/images/blue-button.png',
  '/images/yellow-button.png',
];

export const QuestionContainer = styled.fieldset`
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0;
`;

export const AnswersContainer = styled.div`
  max-width: 1096px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
`;

export const HidedLabel = styled.label``;

export const AnswerWrapper = styled.div`
  position: relative;
`;

export const InputCheckbox = styled.input`
  position: absolute;
  top: 48%;
  transform: translateY(-50%);
  opacity: 0;

  & ~ label {
    position: relative;
    overflow: hidden;
  }

  & ~ label::before {
    content: '';
    position: absolute;
    inset: 0;
    visibility: hidden;
    background-image: ${(props) => {
      return `url(${backgroundImages[props.idx]})`;
    }};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &:checked ~ label::before {
    content: '';
    visibility: visible;
  }

  &:checked ~ label span {
    color: ${(props) => (props.idx === 2 ? COLORS.BLACK : COLORS.WHITE)};
  }

  &:focus-visible ~ label {
    outline: 3px solid ${COLORS.BLUE};
    outline-offset: 1px;
  }
`;

export const AnswerLabel = styled.label`
  border: 1.1px solid black;
  padding: 4px 16px 5px;
  border-radius: 999px;
  display: inline-block;

  font-size: 18px;
  font-weight: ${(props) => (props.label === 'Other' ? '700' : '500')};
  line-height: 32px;

  @media (min-width: ${BREAKPOINTS.PHABLET}px) {
    font-size: 24px;
    padding: 9px 32px 11px;
  }
`;

export const Legend = styled.legend`
  font-size: 34px;
  line-height: 40px;
  padding-top: 80px;
  padding-bottom: 16px;
  width: 100%;
  text-align: center;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    padding-top: 36px;
    padding-bottom: 4px;
  }
`;
