import React from 'react';

import * as Styled from './styles';

const DoneStepImages = () => {
  return (
    <>
      <Styled.ImageDottedLeft
        src="/images/dotted-blue.png"
        alt=""
        width="917"
        height="1074"
      ></Styled.ImageDottedLeft>
      <Styled.ImageCloud
        src="/images/cloud-blue.png"
        alt=""
        width="928"
        height="992"
      ></Styled.ImageCloud>
      <Styled.ImageSquare
        src="/images/square-blue.png"
        alt=""
        width="1274"
        height="1156"
      ></Styled.ImageSquare>
      <Styled.ImageOrange
        src="/images/orange.png"
        alt=""
        width="1013"
        height="1070"
      ></Styled.ImageOrange>
      <Styled.ImageYellow
        src="/images/yellow.png"
        alt=""
        width="1342"
        height="1285"
      ></Styled.ImageYellow>
      <Styled.ImageDottedRight
        src="/images/dotted-blue.png"
        alt=""
        width="917"
        height="1074"
      ></Styled.ImageDottedRight>
      <Styled.ImageCloudRight
        src="/images/cloud-blue.png"
        alt=""
        width="928"
        height="992"
      ></Styled.ImageCloudRight>
      <Styled.ImageOrangeRight
        src="/images/orange.png"
        alt=""
        width="1013"
        height="1070"
      ></Styled.ImageOrangeRight>
    </>
  );
};

export default DoneStepImages;
