import React from 'react';

import * as StyledFormSection from '../FormSection/styles';
import * as StyledForm from '../ConsentForm/styles';
import ErrorMessage from '../ErrorMessage';

const ImagesConsentForm = ({ register, errors, description }) => {
  return (
    <StyledForm.Fieldset>
      <StyledFormSection.SectionFormDescription
        as="legend"
        bigSize={true}
        style={{ textAlign: 'left' }}
      >
        {description}
      </StyledFormSection.SectionFormDescription>
      <div>
        <div>
          <StyledForm.Input
            type="radio"
            id="images-yes"
            name="age"
            value="true"
            {...register('generalImageTermsConfirmation')}
          />
          <StyledForm.Label htmlFor="images-yes">Confirm</StyledForm.Label>
        </div>
        <div>
          <StyledForm.Input
            type="radio"
            id="images-no"
            name="age"
            value="false"
            {...register('generalImageTermsConfirmation')}
          />
          <StyledForm.Label htmlFor="images-no">Do not confirm</StyledForm.Label>
        </div>
      </div>

      {errors?.['generalImageTermsConfirmation']?.message && (
        <ErrorMessage error={errors?.['generalImageTermsConfirmation']?.message} />
      )}
    </StyledForm.Fieldset>
  );
};

export default ImagesConsentForm;
