import React, { useEffect, useState, useRef } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { navigate } from '@reach/router';
import * as yup from 'yup';
import qs from 'qs';
import { yupResolver } from '@hookform/resolvers/yup';
import loadable from '@loadable/component';
import slugify from 'slugify';
import { usePrevious } from 'react-use';

import { generateId } from '../../lib/utils';
import { contentFetch } from '../../lib/content-fetcher';
import { postData, fetchData, putData } from '../../utils/api';
import { getAuthToken } from '../../lib/local-storage';
import {
  getTemporaryData,
  getWelcomeTemporaryData,
  removeAllTemporaryData,
} from '../../utils/local-storage';
import { addUrlProtocolIfMissing, API_HOSTNAME } from '../../utils/urls';
import { getIsTouchDevice } from '../../utils/mobile';
import { testUrl } from '../../utils/regex';

import {
  KEY_REGISTRATION_TEMPORARY_DATA,
  KEY_WELCOME_TEMPORARY_DATA,
  SHORT_INPUT_MAX_LENGTH,
  DEFAULT_INPUT_MAX_LENGTH,
  LONG_INPUT_MAX_LENGTH,
  YUP_MESSAGES,
  MEMBER_TYPES,
} from '../../utils/constants';

import SEO from '../SEO';
import ProgressBar from './ProgressBar';
import NavigationFooter from './NavigationFooter';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import ThirdStep from './ThirdStep';
import FourthStep from './FourthStep';
import DoneStep from './DoneStep';
import TermsOfUseModal from './TermsOfUseModal';
import { Logo, MenuButton, TitleNextToLogo } from '../HomeHeader/styles';

const CookieBanner = loadable(() => import('../Footer/CookieBanner'));
const Header = loadable(() => import('../Header'));

const STEPS_COUNT = 4;

const hasArrayItems = (arr) => {
  return Array.isArray(arr) && arr.length > 0;
};

const userDataQuery = qs.stringify(
  {
    populate: {
      socialMediaLinks: {
        populate: '*',
      },
      typeOfMember: {
        fields: ['label', 'slug'],
      },
      operatingIn: { fields: ['name', 'slug'] },
      artsAre: { fields: ['name', 'slug'] },
      image: { populate: '*' },
      gender: { fields: ['name', 'slug'] },
      linked_to_users: { fields: ['id'] },
      basedIn: { fields: ['name', 'slug', 'isoCode'] },
      imagesOfWork: { populate: '*' },
      workLinks: { populate: '*' },
      organizationType: { fields: ['name', 'slug'] },
      individualProfiles: { fields: ['name', 'slug'] },
      artTypes: { fields: ['name', 'slug'] },
      themes: { fields: ['name', 'slug'] },
      processedImage: { populate: '*' },
      workScale: { fields: ['name', 'slug'] },
      socialMediaConsentCustomSelection: { populate: '*' },
      countries: { fields: ['name', 'slug', 'isoCode'] },
      mainSectorType: { fields: ['name', 'slug'] },
      logo: { populate: '*' },
    },
  },
  {
    encodeValuesOnly: true,
  },
);

const extractIdsArray = (data, convertToString = false) => {
  return data?.length ? data.map((item) => (convertToString ? String(item.id) : item.id)) : [];
};

const formatSurveyUserInfo = (userData) => {
  if (!userData) return {};

  const imageId = userData?.image?.id;
  const logoId = userData?.logo?.id;

  return {
    ...userData,
    typeOfMember: userData?.typeOfMember?.id,
    gender: userData?.gender?.id,
    basedIn: userData?.basedIn?.id,
    mainSectorType: userData?.mainSectorType?.id,
    website: addUrlProtocolIfMissing(userData?.website),
    socialMediaLinks: userData?.socialMediaLinks.map((item) => ({
      ...item,
      link: addUrlProtocolIfMissing(item.link),
    })),
    workLinks: userData?.workLinks.map((item) => ({
      ...item,
      link: addUrlProtocolIfMissing(item.link),
    })),
    image: imageId ? [imageId] : null,
    logo: logoId ? [logoId] : null,
    linked_to_users: extractIdsArray(userData.linked_to_users, true),
    artTypes: extractIdsArray(userData.artTypes, true),
    themes: extractIdsArray(userData.themes, true),
    individualProfiles: extractIdsArray(userData.individualProfiles),
    organizationType: extractIdsArray(userData.organizationType),
    workScale: extractIdsArray(userData.workScale),
    countries: extractIdsArray(userData.countries),
    imagesOfWork: extractIdsArray(userData.imagesOfWork),
    socialMediaConsentCustomSelection:
      userData.socialMediaConsent === 'Custom' &&
      extractIdsArray(userData.socialMediaConsentCustomSelection, true),
    generalImageTermsConfirmation: userData.generalImageTermsConfirmation?.toString(),
  };
};

const formatUserSubmitData = (data) => {
  if (!data) return {};

  const newData = {
    typeOfMember: data.typeOfMember,
    operatingIn: data.operatingIn,
    artsAre: data.artsAre,

    firstName: data.firstName,
    lastName: data.lastName,
    organizationName: data.organizationName,

    individualProfiles: data.individualProfiles,
    individualProfilesOther: data.individualProfilesOther,
    organizationType: data.organizationType,
    organizationTypeOther: data.organizationTypeOther,

    basedIn: data.basedIn,

    gender: data.gender,
    genderOther: data.genderOther,

    summary: data.summary,
    mainSectorType: data.mainSectorType,
    artTypes: data.artTypes || null,
    artTypesOther: data.artTypesOther,
    themes: data.themes || null,
    themesOther: data.themesOther,
    workScale: data.workScale,
    countries: data.countries,
    website: addUrlProtocolIfMissing(data.website),

    image: data.image?.length ? data.image : null,
    imageOwnerConfirmation: data.imageOwnerConfirmation,
    imageCredits: data.imageCredits,

    logo: data.logo?.length ? data.logo : null,
    logoOwnerConfirmation: data.logoOwnerConfirmation,

    imagesOfWork: data.imagesOfWork,
    imagesOfWorkCredits: data.imagesOfWorkCredits,
    imagesOfWorkOwnerConfirmation: data.imagesOfWorkOwnerConfirmation,

    socialMediaLinks: data.socialMediaLinks
      ?.filter((socialLink) => socialLink.link)
      .map((socialLink) => ({
        ...socialLink,
        link: addUrlProtocolIfMissing(socialLink.link),
      })),
    workLinks: data.workLinks
      ?.filter((workLink) => workLink.link)
      .map((workLink) => ({
        ...workLink,
        link: addUrlProtocolIfMissing(workLink.link),
      })),
    linked_to_users: data.linked_to_users,

    socialMediaConsent: data.socialMediaConsent,
    socialMediaConsentCustomSelection:
      data.socialMediaConsent === 'Custom'
        ? data.socialMediaConsentCustomSelection?.map((selection) => parseInt(selection))
        : null,
    publicEmail: data.publicEmail?.length ? data.publicEmail : null,
    organizationContactPersonName: data?.organizationContactPersonName,

    generalImageTermsConfirmation: data.generalImageTermsConfirmation,
  };

  if (data.password.length > 0) {
    newData.password = data.password;
  }

  return newData;
};

const generateUsername = (data, typeOfMemberOptions) => {
  const selectedTypeOfMember =
    typeOfMemberOptions?.data?.length &&
    typeOfMemberOptions.data.find((member) => member.id === data?.typeOfMember)?.attributes?.label;

  let username;

  if (selectedTypeOfMember === MEMBER_TYPES.INDIVIDUAL) {
    username = slugify(`${data.firstName || ''} ${data.lastName || ''}-${generateId(4)}`, {
      strict: true,
    });
  } else {
    username = slugify(`${data.organizationName || ''}-${generateId(4)}`, {
      strict: true,
    });
  }
  return username;
};

const Registration = ({ step }) => {
  const isTouchDevice = getIsTouchDevice();
  const [menuIsShown, showMenu] = React.useState(false);

  const [loadingUserDataState, setLoadingUserDataState] = useState(
    /** @type {'idle' | 'loading' | 'done'} */ ('idle'),
  );

  const beforeUnloadController = useRef(null);

  const [userInfo, setUserInfo] = useState(null);
  const [error, setError] = useState(null);

  const [imageInitialId, setImageInitialId] = useState();
  const [logoInitialId, setLogoInitialId] = useState();
  const [imagesOfWorkInitialIds, setImagesOfWorkInitialIds] = useState();

  const [termsOfUseContent, setTermsOfUseContent] = useState(null);
  const [isTermsUseModalOpen, setIsTermsUseModalOpen] = useState(false);
  const [isDeclinedTermsModalOpen, setIsDeclinedTermsModalOpen] = useState(false);
  const [showTermsUseModalFooterBtns, setShowTermsUseModalFooterBtns] = useState(false);

  const [postState, setPostState] = useState(
    /** @type {'idle' | 'loading' | 'done' | 'error'} */ ('idle'),
  );

  yup.setLocale({
    mixed: {
      required: YUP_MESSAGES.REQUIRED,
    },
  });

  const [mainSectorTypeOptions, setMainSectorTypeOptions] = useState(null);
  const [workScaleOptions, setWorkScaleOptions] = useState(null);
  const [socialMediaTypeOptions, setSocialMediaTypeOptions] = useState(null);
  const [canSocialMediaTypeOptions, setCanSocialMediaTypeOptions] = useState(null);
  const [artTypesOptions, setArtTypesOptions] = useState(null);
  const [themesOptions, setThemesOptions] = useState(null);
  const [countriesOptions, setCountriesOptions] = useState(null);
  const [typeOfMemberOptions, setTypeOfMemberOptions] = useState(null);
  const [individualProfileOptions, setIndividualProfileOptions] = useState(null);
  const [organizationTypeOptions, setOrganizationTypeOptions] = useState(null);
  const [genderOptions, setGenderOptions] = useState(null);

  const showTermsOfUseInfoOnlyModal = () => {
    setShowTermsUseModalFooterBtns(false);
    setIsTermsUseModalOpen(true);
  };

  const onMeUserDataLoaded = async (userData) => {
    const userId = userData.id;
    if (!userId) {
      const temporaryData = getTemporaryData();
      const welcomeTemporaryData = getWelcomeTemporaryData();

      methods.reset({
        typeOfMember: parseInt(welcomeTemporaryData?.typeOfMember),
        operatingIn: parseInt(welcomeTemporaryData?.operatingIn),
        ...temporaryData,
      });
      setImageInitialId(temporaryData?.image);
      setLogoInitialId(temporaryData?.logo);
      setImagesOfWorkInitialIds(temporaryData?.imagesOfWork);

      methods.trigger();
      setTimeout(() => {
        methods.clearErrors();
        setLoadingUserDataState('done');
      }, 0);

      return;
    }

    const surveyUserInfo = await fetchData(`${API_HOSTNAME}/users/${userId}?${userDataQuery}`);
    const formattedSurveyUserInfo = formatSurveyUserInfo(surveyUserInfo);
    setUserInfo(formattedSurveyUserInfo);

    if (formattedSurveyUserInfo) {
      methods.reset(formattedSurveyUserInfo);
      setImageInitialId(formattedSurveyUserInfo.image);
      setLogoInitialId(formattedSurveyUserInfo.logo);
      setImagesOfWorkInitialIds(formattedSurveyUserInfo.imagesOfWork);
    }

    setTimeout(() => {
      methods.trigger();
      setTimeout(() => {
        methods.clearErrors();
        setLoadingUserDataState('done');
      }, 0);
    }, 300);
  };

  React.useEffect(async () => {
    setLoadingUserDataState('loading');

    contentFetch({
      pathname: '/users/me?populate=*',
      setState: onMeUserDataLoaded,
    });

    const termsUseQuery = qs.stringify(
      {
        fields: ['preTermsOfUse', 'termsOfUse'],
      },
      {
        encodeValuesOnly: true,
      },
    );
    contentFetch({
      pathname: `/legal-text?${termsUseQuery}`,
      setState: setTermsOfUseContent,
      transform: (d) => `${d.data.attributes.preTermsOfUse || ''} ${d.data.attributes.termsOfUse}`,
    });
  }, []);

  const getIsOrganization = (selectedTypeOfMemberId, typeOfMember) => {
    const isOrganization =
      typeOfMember?.data?.find((el) => String(el.id) === String(selectedTypeOfMemberId))?.attributes
        ?.label === MEMBER_TYPES.ORGANISATION;
    return isOrganization;
  };

  const validationStepsSchema = [
    // Validation for Step 1
    yup.object().shape({
      typeOfMember: yup.string().required(),
      firstName: yup
        .string()
        .nullable()
        .when('typeOfMember', {
          is: (selectedTypeOfMemberId) => {
            return !getIsOrganization(selectedTypeOfMemberId, typeOfMemberOptions);
          },
          then: () =>
            yup
              .string()
              .max(SHORT_INPUT_MAX_LENGTH)
              .required()
              .typeError('Please provide a valid First name'),
        }),
      lastName: yup
        .string()
        .nullable()
        .when('typeOfMember', {
          is: (selectedTypeOfMemberId) => {
            return !getIsOrganization(selectedTypeOfMemberId, typeOfMemberOptions);
          },
          then: () =>
            yup
              .string()
              .max(SHORT_INPUT_MAX_LENGTH)
              .required()
              .typeError('Please provide a valid Last name'),
        }),
      organizationName: yup
        .string()
        .nullable()
        .when('typeOfMember', {
          is: (selectedTypeOfMemberId) => {
            return getIsOrganization(selectedTypeOfMemberId, typeOfMemberOptions);
          },
          then: () =>
            yup
              .string()
              .max(DEFAULT_INPUT_MAX_LENGTH)
              .required()
              .typeError('Please provide a valid Organisation name'),
        }),
      organizationType: yup.array().nullable(),
      individualProfiles: yup.array().nullable(),
      basedIn: yup.string().nullable(),
      summary: yup.string().nullable().max(LONG_INPUT_MAX_LENGTH),
      imageCredits: yup.string().nullable().max(DEFAULT_INPUT_MAX_LENGTH),
      logo: yup.array(yup.number()).nullable(),
      logoOwnerConfirmation: yup
        .boolean()
        .nullable()
        .when(['typeOfMember', 'logo'], {
          is: (selectedTypeOfMemberId, logo) =>
            getIsOrganization(selectedTypeOfMemberId, typeOfMemberOptions) && hasArrayItems(logo),
          then: () =>
            yup
              .boolean()
              .oneOf([true], 'You must confirm the permission')
              .required()
              .typeError('You must confirm the permission'),
        }),
      image: yup.array(yup.number()).nullable(),
      imageOwnerConfirmation: yup
        .boolean()
        .nullable()
        .when(['typeOfMember', 'image'], {
          is: (selectedTypeOfMemberId, image) =>
            !getIsOrganization(selectedTypeOfMemberId, typeOfMemberOptions) && hasArrayItems(image),
          then: () =>
            yup
              .boolean()
              .oneOf([true], 'You must confirm the permission')
              .required()
              .typeError('You must confirm the permission'),
        }),
    }),
    // Validation for Step 2
    yup.object().shape({
      mainSectorType: yup.string().nullable(),
      artTypes: yup.array().nullable(),
      artTypesOther: yup.string().nullable().max(DEFAULT_INPUT_MAX_LENGTH),
      themes: yup.array().nullable(),
      themesOther: yup.string().nullable().max(DEFAULT_INPUT_MAX_LENGTH),
      workScale: yup.array().nullable(),
      countries: yup.array().nullable(),
      website: yup
        .string()
        .nullable()
        .label('Website link')
        .test('is-valid-website-url', YUP_MESSAGES.URL_PATTERN, testUrl)
        .max(DEFAULT_INPUT_MAX_LENGTH),
      socialMedia: yup.array().nullable(),
      socialMediaLinks: yup
        .array()
        .nullable()
        .of(
          yup.object().shape({
            type: yup.object().shape({
              id: yup.number().required(),
            }),
            link: yup
              .string()
              .required()
              .label('Social media links')
              .test('is-valid-social-media-url', YUP_MESSAGES.URL_PATTERN, testUrl)
              .max(DEFAULT_INPUT_MAX_LENGTH),
          }),
        ),
      workLinks: yup
        .array()
        .nullable()
        .of(
          yup.object().shape({
            link: yup
              .string()
              .required()
              .label('Work links')
              .test('is-valid-work-url', YUP_MESSAGES.URL_PATTERN, testUrl)
              .max(DEFAULT_INPUT_MAX_LENGTH),
          }),
        ),
      imageCredits: yup.string().nullable().max(DEFAULT_INPUT_MAX_LENGTH),
      imagesOfWorkCredits: yup.string().nullable().max(DEFAULT_INPUT_MAX_LENGTH),
      image: yup.array(yup.number()).nullable(),
      imageOwnerConfirmation: yup
        .boolean()
        .nullable()
        .when(['typeOfMember', 'image'], {
          is: (selectedTypeOfMemberId, image) =>
            getIsOrganization(selectedTypeOfMemberId, typeOfMemberOptions) && hasArrayItems(image),
          then: () =>
            yup
              .boolean()
              .oneOf([true], 'You must confirm the permission')
              .required()
              .typeError('You must confirm the permission'),
        }),
      imagesOfWork: yup.array(yup.number()).nullable(),
      imagesOfWorkOwnerConfirmation: yup
        .boolean()
        .nullable()
        .when('imagesOfWork', {
          is: (imagesOfWork) => hasArrayItems(imagesOfWork),
          then: () =>
            yup
              .boolean()
              .oneOf([true], 'You must confirm the permission')
              .required()
              .typeError('You must confirm the permission'),
        }),
    }),
    // Validation for Step 3
    yup.object().shape({
      linked_to_users: yup.array().nullable(),
    }),
    // Validation for Step 4
    yup.object().shape({
      id: yup.string().nullable(),
      organizationContactPersonName: yup.string().nullable().max(DEFAULT_INPUT_MAX_LENGTH),
      email: yup
        .string()
        .email()
        .when('id', {
          is: (id) => {
            return id == null || id === '';
          },
          then: () => yup.string().email().max(DEFAULT_INPUT_MAX_LENGTH).required(),
        }),
      publicEmail: yup.string().nullable().max(DEFAULT_INPUT_MAX_LENGTH),
      password: yup.string().when('id', {
        is: (id) => {
          return id == null || id === '';
        },
        then: () => yup.string().min(6).max(DEFAULT_INPUT_MAX_LENGTH).required(),
      }),
      repeatPassword: yup.string().when('id', {
        is: (id) => {
          return id == null || id === '';
        },
        then: () =>
          yup
            .string()
            .required()
            .oneOf([yup.ref('password'), null], 'Passwords must match'),
      }),
      socialMediaConsent: yup.string().required().typeError('Please select a consent'),
      generalImageTermsConfirmation: yup
        .boolean()
        .nullable()
        .when(['image', 'logo', 'imagesOfWork'], {
          is: (image, logo, imagesOfWork) => [image, logo, imagesOfWork].some(hasArrayItems),
          then: yup
            .boolean()
            .oneOf(
              [true],
              'Please confirm the use of images or go back and delete the images you uploaded',
            )
            .required()
            .typeError(
              'Please confirm the use of images or go back and delete the images you uploaded',
            ),
        }),
      socialMediaConsentCustomSelection: yup.array().nullable(),
    }),
  ];

  const currentValidationSchema = validationStepsSchema?.[parseInt(step)] || yup.object();

  const methods = useForm({
    resolver: yupResolver(currentValidationSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    const { unsubscribe } = methods.watch((values) => {
      if (loadingUserDataState !== 'done') return;
      saveTemporaryData(values);
    });
    return () => unsubscribe();
  }, [methods.watch, loadingUserDataState]);

  const userId = methods.watch('id');

  useEffect(() => {
    if (loadingUserDataState !== 'done') return;

    methods.trigger();
    setTimeout(() => {
      methods.clearErrors();
    }, 0);
  }, [step, methods, loadingUserDataState]);

  useEffect(() => {
    const fetchDataAndSetValue = async () => {
      await fetchData(`${API_HOSTNAME}/user-survey-type-of-members`).then(setTypeOfMemberOptions);

      fetchData(`${API_HOSTNAME}/user-survey-main-sector-types`).then(setMainSectorTypeOptions);
      fetchData(`${API_HOSTNAME}/user-survey-work-scales?sort=slug`).then(setWorkScaleOptions);
      fetchData(`${API_HOSTNAME}/user-survey-social-media-types?sort=slug`).then(
        setSocialMediaTypeOptions,
      );
      fetchData(`${API_HOSTNAME}/user-survey-can-social-media-types?sort=slug`).then(
        setCanSocialMediaTypeOptions,
      );
      fetchData(`${API_HOSTNAME}/user-survey-art-types?sort=slug`).then(setArtTypesOptions);
      fetchData(`${API_HOSTNAME}/user-survey-themes?sort=slug`).then(setThemesOptions);

      const countriesQuery = qs.stringify(
        {
          pagination: {
            limit: -1,
          },
          fields: ['name', 'isoCode'],
          sort: 'name:asc',
        },
        {
          encodeValuesOnly: true,
        },
      );
      fetchData(`${API_HOSTNAME}/countries?${countriesQuery}`).then(setCountriesOptions);

      fetchData(`${API_HOSTNAME}/user-individual-profiles?sort=slug`).then(
        setIndividualProfileOptions,
      );
      fetchData(`${API_HOSTNAME}/user-organization-types?sort=slug`).then(
        setOrganizationTypeOptions,
      );
      fetchData(`${API_HOSTNAME}/user-gender-types`).then(setGenderOptions);
    };

    fetchDataAndSetValue();
  }, []);

  const saveTemporaryData = (data, setImagesIds = false) => {
    localStorage.setItem(
      KEY_REGISTRATION_TEMPORARY_DATA,
      JSON.stringify({
        ...data,
        website: addUrlProtocolIfMissing(data?.website),
        socialMediaLinks: data?.socialMediaLinks?.map((item) => ({
          ...item,
          link: addUrlProtocolIfMissing(item.link),
        })),
        workLinks: data?.workLinks?.map((item) => ({
          ...item,
          link: addUrlProtocolIfMissing(item.link),
        })),
      }),
    );
    if (setImagesIds) {
      setImageInitialId(data.image);
      setLogoInitialId(data.logo);
      setImagesOfWorkInitialIds(data.imagesOfWork);
    }
  };

  const onBeforeUnload = (e) => {
    e.preventDefault();
    e.returnValue = true;
  };

  const sendUserData = async (data) => {
    if (postState === 'loading') return;

    setPostState('loading');
    setError(null);

    const formattedUserDataToSubmit = formatUserSubmitData(data);

    if (data.id) {
      await putData(`${API_HOSTNAME}/users/${data.id}`, {
        ...formattedUserDataToSubmit,
      })
        .then(() => {
          setPostState('done');
          removeAllTemporaryData();
          beforeUnloadController.current?.abort();
          navigate(`/profile/${parseInt(step) + 1}`);
        })
        .catch((err) => {
          console.error(err);
          setTimeout(() => {
            setPostState('error');
            setError(err.message);
          }, 1000);
        });
    } else {
      const username = generateUsername(data, typeOfMemberOptions);

      await postData(`${API_HOSTNAME}/auth/local/register`, {
        username,
        email: data.email,
        ...formattedUserDataToSubmit,
      })
        .then(() => {
          setPostState('done');
          removeAllTemporaryData();
          beforeUnloadController.current?.abort();
          navigate(`/profile/${parseInt(step) + 1}`);
        })
        .catch((err) => {
          console.error(err);
          setTimeout(() => {
            setPostState('error');
            setError(err.message);
          }, 1000);
        });
    }
  };

  const getStepSubmitType = () => {
    if (parseInt(step) < STEPS_COUNT - 1) return 'continue';
    if (parseInt(step) === STEPS_COUNT - 1) return 'submit';

    return 'none';
  };
  /**
   * @type {'none' | 'continue' | 'submit' }
   */
  const stepSubmitType = getStepSubmitType();

  const onSubmit = async (data) => {
    if (stepSubmitType === 'submit' && !Object.keys(methods.formState.errors).length) {
      if (data.id) {
        sendUserData(methods.getValues());
      } else {
        setShowTermsUseModalFooterBtns(true);
        setIsTermsUseModalOpen(true);
      }
    } else if (stepSubmitType === 'continue') {
      if (!methods.formState.isValid) return;

      saveTemporaryData(methods.getValues(), true);
      navigate(`/profile/${parseInt(step) + 1}`);
    }
  };

  const onBack = (e) => {
    e.preventDefault();
    navigate(`/profile/${parseInt(step) - 1}`);
  };

  const onAcceptTermsUseModal = () => {
    sendUserData(methods.getValues());
  };

  const onDeclineTermsUseModal = () => {
    setIsDeclinedTermsModalOpen(true);
  };
  const authToken = getAuthToken();

  const selectedTypeOfMemberId = methods.watch('typeOfMember');
  const prevTypeOfMemberId = usePrevious(selectedTypeOfMemberId);
  const isOrganization = getIsOrganization(selectedTypeOfMemberId, typeOfMemberOptions);

  useEffect(() => {
    if (userId) return;
    if (!prevTypeOfMemberId || prevTypeOfMemberId === selectedTypeOfMemberId) return;

    localStorage.removeItem(KEY_REGISTRATION_TEMPORARY_DATA);

    setImageInitialId(undefined);
    setLogoInitialId(undefined);
    setImagesOfWorkInitialIds(undefined);

    const welcomeTemporaryData = getWelcomeTemporaryData();

    localStorage.setItem(
      KEY_WELCOME_TEMPORARY_DATA,
      JSON.stringify({ ...welcomeTemporaryData, typeOfMember: String(selectedTypeOfMemberId) }),
    );

    methods.reset({
      operatingIn: parseInt(welcomeTemporaryData?.operatingIn),
      typeOfMember: selectedTypeOfMemberId,
    });
  }, [selectedTypeOfMemberId]);

  useEffect(() => {
    const { isDirty } = methods.formState;

    if (isDirty) {
      beforeUnloadController.current = new AbortController();
      window.addEventListener('beforeunload', onBeforeUnload, {
        signal: beforeUnloadController.current.signal,
      });
    } else {
      beforeUnloadController.current?.abort();
    }

    return () => {
      beforeUnloadController.current?.abort();
    };
  }, [methods.formState.isDirty]);

  return (
    <>
      <SEO title="Profile"></SEO>

      <div style={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 60 }}>
        <Header narrow menuIsShown={menuIsShown} showMenu={showMenu} scrolled />
        <Logo
          narrow
          style={{ position: 'fixed' }}
          visible={true}
          fixedBackgroundAttachment={false}
        />
        <TitleNextToLogo style={{ position: 'fixed' }}>
          {authToken ? 'Your Profile' : 'Registration'}
        </TitleNextToLogo>
        <MenuButton
          narrow
          style={{ position: 'fixed' }}
          visible={true}
          menuIsShown={menuIsShown}
          fixedBackgroundAttachment={false}
        />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <ProgressBar totalSteps={STEPS_COUNT} step={parseInt(step)} />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {parseInt(step) === 0 ? (
              <FirstStep
                errors={methods.formState.errors}
                register={methods.register}
                countries={countriesOptions}
                typeOfMember={typeOfMemberOptions}
                individualProfile={individualProfileOptions}
                organizationType={organizationTypeOptions}
                gender={genderOptions}
                userInfo={userInfo}
                control={methods.control}
                watch={methods.watch}
                setValue={methods.setValue}
                step={parseInt(step)}
                imageInitialId={imageInitialId}
                logoInitialId={logoInitialId}
                isOrganization={isOrganization}
                isMobile={isTouchDevice}
                isNewProfile={!userId}
              />
            ) : parseInt(step) === 1 ? (
              <SecondStep
                errors={methods.formState.errors}
                register={methods.register}
                countries={countriesOptions}
                mainSectorType={mainSectorTypeOptions}
                workScale={workScaleOptions}
                artTypes={artTypesOptions}
                themes={themesOptions}
                socialMediaType={socialMediaTypeOptions}
                control={methods.control}
                watch={methods.watch}
                userInfo={userInfo}
                setValue={methods.setValue}
                step={parseInt(step)}
                imageInitialId={imageInitialId}
                imagesOfWorkInitialIds={imagesOfWorkInitialIds}
                isOrganization={isOrganization}
                isMobile={isTouchDevice}
              />
            ) : parseInt(step) === 2 ? (
              <ThirdStep
                register={methods.register}
                watch={methods.watch}
                errors={methods.formState.errors}
                setValue={methods.setValue}
                userInfo={userInfo}
                step={parseInt(step)}
              />
            ) : parseInt(step) === 3 ? (
              <FourthStep
                errors={methods.formState.errors}
                register={methods.register}
                watch={methods.watch}
                getValues={methods.getValues}
                workScale={workScaleOptions}
                organizationType={organizationTypeOptions}
                artTypes={artTypesOptions}
                themes={themesOptions}
                typeOfMember={typeOfMemberOptions}
                individualProfile={individualProfileOptions}
                countries={countriesOptions}
                mainSectorType={mainSectorTypeOptions}
                canSocialMediaType={canSocialMediaTypeOptions}
                gender={genderOptions}
                step={parseInt(step)}
                isOrganization={isOrganization}
                termsOfUseContent={termsOfUseContent}
                showTermsOfUseInfoOnlyModal={showTermsOfUseInfoOnlyModal}
              />
            ) : parseInt(step) === 4 ? (
              <DoneStep watch={methods.watch} />
            ) : null}

            {parseInt(step) !== STEPS_COUNT && (
              <NavigationFooter
                isStepValid={methods.formState.isValid}
                canGoBack={parseInt(step) > 0 && STEPS_COUNT !== parseInt(step)}
                onBack={onBack}
                stepSubmitType={stepSubmitType}
                postState={postState}
                error={error}
                isNewProfile={!userId}
              ></NavigationFooter>
            )}
            <TermsOfUseModal
              isOpen={isTermsUseModalOpen}
              setIsOpen={setIsTermsUseModalOpen}
              onAcceptTermsUseModal={onAcceptTermsUseModal}
              onDeclineTermsUseModal={onDeclineTermsUseModal}
              termsOfUseContent={termsOfUseContent}
              type="termsOfUse"
              showFooterBtns={showTermsUseModalFooterBtns}
            ></TermsOfUseModal>
            <TermsOfUseModal
              isOpen={isDeclinedTermsModalOpen}
              setIsOpen={setIsDeclinedTermsModalOpen}
              type="declined"
            ></TermsOfUseModal>
          </form>
        </FormProvider>

        <CookieBanner></CookieBanner>
      </div>
    </>
  );
};

export default Registration;
