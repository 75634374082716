import * as React from 'react';
import { Router } from '@reach/router';

import Registration from '../components/NewRegisterForm/view';

const RegistrationSteps = () => {
  return (
    <div>
      <Router>
        <Registration path="/profile/:step" />
      </Router>
    </div>
  );
};

export default RegistrationSteps;
