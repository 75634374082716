import React from 'react';

import { NewButton } from '../../NewButton';
import LoadingSpinner from '../LoadingSpinner';
import IconError from '../../../assets/error.svg';

import * as Styled from './styles';

const NavigationFooter = ({
  isStepValid,
  canGoBack,
  onBack,
  stepSubmitType,
  postState,
  error,
  isNewProfile,
}) => {
  return (
    <Styled.NavigationFooterGlobalContainer>
      <div role="region" aria-live="assertive">
        {postState === 'error' && (
          <Styled.ErrorMessage>
            <IconError />
            <span style={{ marginTop: '-1px' }}>{error}</span>
          </Styled.ErrorMessage>
        )}
      </div>
      <Styled.NavigationFooterContainer isStepValid={isStepValid}>
        <Styled.Text isStepValid={isStepValid}>
          {isStepValid
            ? 'You can continue with the next step. Please go until the last step to submit your changes.'
            : '*Some mandatory fields are missing.'}
        </Styled.Text>
        <Styled.ButtonsContainer>
          {canGoBack && (
            <NewButton
              type="button"
              variant="secondary"
              style={{
                background: 'transparent',
                color: 'white',
                border: '1px solid white',
                minWidth: '150px',
              }}
              onClick={onBack}
            >
              Previous
            </NewButton>
          )}
          {stepSubmitType === 'continue' && (
            <NewButton
              type="submit"
              variant={isStepValid ? 'tertiary' : 'secondary'}
              style={{ minWidth: '150px' }}
            >
              Continue
            </NewButton>
          )}
          {stepSubmitType === 'submit' && (
            <NewButton type="submit" variant="secondary" style={{ minWidth: '150px' }}>
              {postState === 'loading' ? <LoadingSpinner /> : 'Continue'}
            </NewButton>
          )}
        </Styled.ButtonsContainer>
      </Styled.NavigationFooterContainer>
    </Styled.NavigationFooterGlobalContainer>
  );
};

export default NavigationFooter;
