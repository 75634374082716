import React from 'react';
import StepHeader from '../StepHeader';

import { COLORS } from '../../../utils/theme';

import * as Styled from './styles';

import SocialMediaContainer from '../../SocialMediaContainer';
import DoneStepImages from '../DoneStepImages';

import Newsletter from '../Newsletter';

const DoneStep = ({ watch }) => {
  const userId = watch('id');
  return (
    <Styled.GlobalContainer finalStep={true}>
      <Styled.ContentContainer>
        <StepHeader
          smallPadding={true}
          smallDescription={true}
          finalStep={true}
          title={
            userId
              ? 'Thank you for updating your profile!'
              : 'You are just one step away from completing your registration'
          }
          subtitle={
            userId
              ? 'Your data is now saved.'
              : `<strong style="color: ${COLORS.BLUE}">Please check your inbox & spam</strong> for a confirmation email containing a verification link. <strong style="color: ${COLORS.BLUE}">Click the verification link</strong> to complete and submit your profile registration.`
          }
          hasUserId={!!userId}
        ></StepHeader>
      </Styled.ContentContainer>

      <Newsletter />

      <DoneStepImages />

      <SocialMediaContainer label="Connect with us via our social media channels!"></SocialMediaContainer>
    </Styled.GlobalContainer>
  );
};

export default DoneStep;
