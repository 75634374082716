import styled from 'styled-components';

import { BREAKPOINTS } from '../../../utils/theme';

export const ImageDottedLeft = styled.img`
  position: absolute;
  width: 500px;
  height: auto;
  top: -20px;
  left: -120px;
  transform: rotate(200deg) scaleX(-1);

  @media (max-width: ${BREAKPOINTS.XL_DESKTOP}px) {
    left: -160px;
  }

  @media (max-width: ${BREAKPOINTS.DESKTOP}px) {
    width: 400px;
    left: -155px;
  }

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    display: none;
  }
`;

export const ImageCloud = styled.img`
  position: absolute;
  width: 350px;
  height: auto;
  top: 40px;
  left: -130px;
  transform: scaleX(-1);
  z-index: 4;

  @media (max-width: ${BREAKPOINTS.DESKTOP}px) {
    width: 270px;
    left: -90px;
  }

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    display: none;
  }
`;

export const ImageSquare = styled.img`
  position: absolute;
  width: 550px;
  height: auto;
  top: 40px;
  left: -10px;
  z-index: 3;

  @media (max-width: ${BREAKPOINTS.XL_DESKTOP}px) {
    width: 500px;
    top: 60px;
    left: -60px;
  }

  @media (max-width: ${BREAKPOINTS.DESKTOP}px) {
    width: 400px;
    left: -100px;
    top: 80px;
  }

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    width: 450px;
    top: -230px;
    left: -150px;
    transform: scaleY(-1);
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    left: -180px;
  }
`;

export const ImageOrange = styled.img`
  position: absolute;
  width: 450px;
  height: auto;
  top: -260px;
  left: 300px;
  transform: rotate(20deg);
  z-index: 2;

  @media (max-width: ${BREAKPOINTS.XL_DESKTOP}px) {
    width: 400px;
    left: 170px;
  }

  @media (max-width: ${BREAKPOINTS.DESKTOP}px) {
    width: 350px;
    top: -210px;
    left: 120px;
  }

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    z-index: 3;
    left: 230px;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    width: 320px;
    left: 150px;
    top: -230px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    left: 60px;
  }
`;

export const ImageYellow = styled.img`
  position: absolute;
  width: 800px;
  height: auto;
  top: -380px;
  left: 750px;
  transform: scaleX(-1) rotate(-45deg);

  @media (max-width: ${BREAKPOINTS.XL_DESKTOP}px) {
    width: 650px;
    top: -320px;
    left: 550px;
  }

  @media (max-width: ${BREAKPOINTS.DESKTOP}px) {
    width: 400px;
    top: -180px;
    left: 400px;
  }

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    left: -220px;
    top: 150px;
    transform: scaleY(-1) rotate(40deg);
    z-index: 3;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    width: 300px;
    left: -175px;
    top: 100px;
  }
`;

export const ImageDottedRight = styled.img`
  position: absolute;
  width: 520px;
  height: auto;
  top: -280px;
  right: 250px;
  transform: scaleY(-1) rotate(-100deg);
  z-index: 5;

  @media (max-width: ${BREAKPOINTS.XL_DESKTOP}px) {
    top: -340px;
    right: 50px;
  }

  @media (max-width: ${BREAKPOINTS.DESKTOP}px) {
    width: 350px;
    right: 100px;
    top: -210px;
  }

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    width: 400;
    right: -250px;
    top: 250px;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    top: 120px;
    right: -180px;
    width: 300px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    top: 180px;
    width: 270px;
    right: -160px;
  }
`;

export const ImageCloudRight = styled.img`
  position: absolute;
  width: 600px;
  height: auto;
  top: -400px;
  right: -30px;

  @media (max-width: ${BREAKPOINTS.XL_DESKTOP}px) {
    width: 500px;
    top: -350px;
    right: -100px;
  }

  @media (max-width: ${BREAKPOINTS.DESKTOP}px) {
    width: 350px;
    top: -200px;
    right: -20px;
  }

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    transform: scaleY(-1) rotate(10deg);
    right: -20px;
    z-index: 3;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    width: 300px;
    right: -50px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    width: 220px;
    right: -80px;
    top: -100px;
  }
`;

export const ImageOrangeRight = styled.img`
  position: absolute;
  width: 400px;
  height: auto;
  top: 70px;
  right: -70px;

  @media (max-width: ${BREAKPOINTS.XL_DESKTOP}px) {
    top: 70px;
    right: -90px;
  }

  @media (max-width: ${BREAKPOINTS.DESKTOP}px) {
    width: 300px;
    top: 60px;
  }

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    right: auto;
    top: 400px;
    left: -100px;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    width: 200px;
    top: 250px;
    left: -80px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    width: 170px;
    top: 280px;
    left: -60px;
  }
`;
