import React from 'react';
import { Dialog } from '@headlessui/react';
import { COLORS } from '../../../utils/theme';

import * as Styled from './styles';

const TermsOfUseModal = ({
  isOpen,
  setIsOpen,
  onAcceptTermsUseModal,
  onDeclineTermsUseModal,
  termsOfUseContent,
  type,
  showFooterBtns = true,
}) => {
  const dialogPanelElRef = React.useRef(null);
  return (
    <Dialog
      as="div"
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      style={{ position: 'relative', zIndex: '99' }}
      initialFocus={dialogPanelElRef}
    >
      <div
        style={{ position: 'fixed', inset: '0', background: COLORS.BLACK, opacity: '0.6' }}
        aria-hidden="true"
      />

      <Styled.GlobalContainer>
        <Styled.DialogContainer>
          <Styled.DialogPanel as={Dialog.Panel} type={type} ref={dialogPanelElRef} tabIndex="-1">
            <Styled.ModalTitleContainer>
              <Styled.ModalTitle type={type}>
                {type === 'termsOfUse' ? 'Terms of Use' : 'Sorry...'}
              </Styled.ModalTitle>
            </Styled.ModalTitleContainer>
            <Styled.PrivacyContainer>
              {type === 'termsOfUse' ? (
                <div dangerouslySetInnerHTML={{ __html: termsOfUseContent }}></div>
              ) : (
                <p style={{ margin: '0 auto', maxWidth: '352px', textAlign: 'center' }}>
                  Unfortunately, we are not able to welcome you as a member without having accepted
                  the Terms of Use.
                </p>
              )}
            </Styled.PrivacyContainer>

            <Styled.ButtonsContainer type={type}>
              {!showFooterBtns ? (
                <Styled.Button
                  style={{
                    background: COLORS.WHITE,
                    border: `1px solid ${COLORS.BLUE}`,
                    color: COLORS.BLUE,
                  }}
                  type="button"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  Close
                </Styled.Button>
              ) : type === 'termsOfUse' ? (
                <>
                  <div style={{ display: 'flex', columnGap: '4px' }}>
                    <Styled.Button
                      style={{
                        background: COLORS.WHITE,
                        border: `1px solid ${COLORS.BLUE}`,
                        color: COLORS.BLUE,
                      }}
                      type="submit"
                      onClick={() => {
                        setIsOpen(false);
                        onAcceptTermsUseModal();
                      }}
                    >
                      I agree
                    </Styled.Button>
                    <Styled.Button
                      style={{
                        background: COLORS.WHITE,
                        border: `1px solid ${COLORS.BLUE}`,
                        color: COLORS.BLUE,
                      }}
                      onClick={() => {
                        setIsOpen(false);
                        onDeclineTermsUseModal();
                      }}
                    >
                      I disagree
                    </Styled.Button>
                  </div>

                  <p
                    style={{
                      fontSize: '12px',
                      margin: 0,
                      paddingTop: '10px',
                      color: COLORS.DARK_GREY,
                    }}
                  >
                    If you want to become a Member to our Platform you must agree.
                  </p>
                </>
              ) : (
                <>
                  <div style={{ display: 'flex', columnGap: '4px' }}>
                    <Styled.Button
                      style={{
                        background: COLORS.BLUE,
                        border: `1px solid ${COLORS.BLUE}`,
                        color: COLORS.WHITE,
                      }}
                      type="button"
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      Return to form
                    </Styled.Button>
                    <Styled.InternalLink
                      style={{
                        background: COLORS.WHITE,
                        border: `1px solid ${COLORS.BLUE}`,
                        color: COLORS.BLUE,
                      }}
                      to="/"
                    >
                      Go to homepage
                    </Styled.InternalLink>
                  </div>
                </>
              )}
            </Styled.ButtonsContainer>
          </Styled.DialogPanel>
        </Styled.DialogContainer>
      </Styled.GlobalContainer>
    </Dialog>
  );
};

export default TermsOfUseModal;
