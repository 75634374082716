import React, { useState, useEffect } from 'react';
import { COLORS } from '../../../utils/theme';

import StepHeader from '../StepHeader';
import StepInputText from '../StepInputText';
import FormSection from '../FormSection';
import ProfileCard from '../../NewNetwork/ProfileCard';
import ConsentForm from '../ConsentForm';

import qs from 'qs';
import { contentFetch } from '../../../lib/content-fetcher';

import * as StyledFormSection from '../FormSection/styles';
import * as Styled from './styles';
import ImagesConsentForm from '../ImagesConsentForm';
import { DEFAULT_INPUT_MAX_LENGTH } from '../../../utils/constants';
import { addUrlProtocolIfMissing } from '../../../utils/urls';

const FourthStep = ({
  getValues,
  register,
  watch,
  errors,
  workScale,
  organizationType,
  artTypes,
  themes,
  typeOfMember,
  individualProfile,
  countries,
  mainSectorType,
  canSocialMediaType,
  gender,
  step,
  isOrganization,
  termsOfUseContent,
  showTermsOfUseInfoOnlyModal,
}) => {
  const data = getValues();
  const [linkedUsers, setLinkedUsers] = useState(null);
  const [userPhoto, setUserPhoto] = useState(null);
  const [userImagesOfWork, setUserImagesOfWork] = useState(null);

  useEffect(() => {
    if (data?.linked_to_users?.length) {
      const query = qs.stringify(
        {
          filters: {
            member: true,
            publishedAt: {
              $notNull: true,
            },
            id: {
              $in: data?.linked_to_users?.map((userId) => parseInt(userId)),
            },
          },
          populate: {
            image: {
              fields: ['url', 'width', 'height'],
              formats: {
                populate: '*',
              },
            },
            typeOfMember: {
              fields: ['label'],
            },
            individualProfiles: {
              fields: ['name'],
            },
            organizationType: {
              fields: ['name'],
            },
          },
          fields: ['fullName', 'color', 'imageContainSize'],
          sort: ['sortName:asc'],
          // limit: 10,
        },
        {
          encodeValuesOnly: true,
        },
      );
      contentFetch({
        pathname: `/users?${query}`,
        setState: setLinkedUsers,
      });
    }
  }, [data?.linked_to_users?.length]);

  useEffect(() => {
    if (data?.image?.length) {
      contentFetch({
        pathname: `/upload/files/${data?.image}`,
        setState: setUserPhoto,
      });
    }

    //fetch imagesOfWork
    if (data?.imagesOfWork?.length) {
      const promises = data?.imagesOfWork?.map((imageId) => {
        return contentFetch({
          pathname: `/upload/files/${imageId}`,
        }).then((res) => {
          return res;
        });
      });

      Promise.all(promises).then((res) => {
        setUserImagesOfWork(res);
      });
    }
  }, [data?.image, data?.imagesOfWork]);

  const processUserInfo = (data, userData, otherFiled = null) => {
    if (!data || !userData) return;

    const other = data.find((el) => el.attributes?.name?.toLowerCase() === 'other');
    const hasOther = userData?.some((el) => String(el) === String(other?.id));

    const processedInfo = data
      .filter((el) => userData?.length && userData.map((el) => String(el))?.includes(String(el.id)))
      .filter((el) => el.attributes.name?.toLowerCase() !== 'other')
      .map((el) => el?.attributes?.name || el?.attributes?.label);

    const processedInfoWithOther = [...processedInfo, hasOther && otherFiled].filter(Boolean);

    if (processedInfoWithOther.length > 1) {
      const last = processedInfoWithOther.pop();
      processedInfoWithOther.push(`and ${last}`);
    }

    return processedInfoWithOther.join(', ');
  };

  const individualProfileValue = processUserInfo(
    individualProfile?.data,
    data?.individualProfiles,
    data?.individualProfilesOther,
  );
  const organizationTypeValue = processUserInfo(
    organizationType?.data,
    data?.organizationType,
    data?.organizationTypeOther,
  );
  const artTypesValue = processUserInfo(artTypes?.data, data?.artTypes, data?.artTypesOther);
  const themesValue = processUserInfo(themes?.data, data?.themes, data?.themesOther);
  const workScaleValue = processUserInfo(workScale?.data, data?.workScale);
  const conutriesValue = processUserInfo(countries?.data, data?.countries);
  const mainSectorTypeValue = mainSectorType?.data?.find(
    (type) => type?.id === data?.mainSectorType,
  )?.attributes?.name;
  const genderValue = processUserInfo(gender?.data, [data?.gender], data?.genderOther);

  const typeOfMemberValue = typeOfMember?.data?.find((el) => el.id === data.typeOfMember)
    ?.attributes?.label;
  const basedInValue = countries?.data?.find((country) => country?.id === data?.basedIn)?.attributes
    ?.name;

  const socialMediaLinksWithProtocol = data?.socialMediaLinks?.map((item) => ({
    ...item,
    link: addUrlProtocolIfMissing(item.link),
  }));

  const workLinksWithProtocol = data?.workLinks?.map((item) => ({
    ...item,
    link: addUrlProtocolIfMissing(item.link),
  }));

  const websiteWithProtocol = addUrlProtocolIfMissing(data?.website);

  return (
    <div>
      <StepHeader
        title="Please review and <span>confirm</span> the information you have provided <span>is correct.</span>"
        subtitle="This information will be publicly visible on your profile."
        smallDescription={true}
        bgColor={COLORS.BLUE}
        textColor={COLORS.WHITE}
        step={step}
      ></StepHeader>
      <Styled.ProfileContainerWrapper>
        <Styled.ProfileContainer>
          <Styled.TitleContainer>
            <StyledFormSection.SectionFormTitle styledProfile={true}>
              {isOrganization ? 'About your organisation' : 'About you'}
            </StyledFormSection.SectionFormTitle>
            <Styled.InternalLink to="/profile/0" style={{ height: 'fit-content' }}>
              Back to edit
            </Styled.InternalLink>
          </Styled.TitleContainer>
          <Styled.UserDetails>
            <div style={{ paddingBottom: '90px', minWidth: 0 }}>
              <Styled.FullName>
                {isOrganization ? (
                  <span style={{ textTransform: 'capitalize' }}>
                    {data.organizationName || 'No data'}
                  </span>
                ) : data.fistName || data.lastName ? (
                  <>
                    <span style={{ textTransform: 'capitalize' }}>{data.firstName}</span>{' '}
                    <span style={{ textTransform: 'capitalize' }}>{data.lastName}</span>
                  </>
                ) : (
                  'No data'
                )}
              </Styled.FullName>
              <div>
                <Styled.Label>Type of member:</Styled.Label>
                <Styled.Description>{typeOfMemberValue || 'No data'}</Styled.Description>
              </div>

              {!isOrganization && (
                <div>
                  <Styled.Label>Pronouns:</Styled.Label>
                  <Styled.Description>{genderValue || 'No data'}</Styled.Description>
                </div>
              )}

              <div>
                <Styled.Label>Profile:</Styled.Label>
                {isOrganization ? (
                  <Styled.Description>{organizationTypeValue || 'No data'}</Styled.Description>
                ) : (
                  <Styled.Description>{individualProfileValue || 'No data'}</Styled.Description>
                )}
              </div>

              <div style={{ paddingBottom: '35px' }}>
                <Styled.Label>Base location:</Styled.Label>
                <Styled.Description>{basedInValue || 'No data'}</Styled.Description>
              </div>

              <div>
                <Styled.Label>Summary bio:</Styled.Label>
                <Styled.Description style={{ fontSize: '16px' }}>
                  {data.summary || 'No data'}
                </Styled.Description>
              </div>
            </div>

            {userPhoto?.url && (
              <Styled.ImageProfileContainer>
                <div style={{ position: 'relative' }}>
                  <Styled.UserImageLines
                    src="/images/user-image-lines.png"
                    alt=""
                    width="408"
                    height="554"
                    side="right"
                  ></Styled.UserImageLines>
                  <Styled.ImageProfile
                    data-image-contain-size={!!data.imageContainSize}
                    src={userPhoto?.url}
                    alt={data.imageCredits || ''}
                    width={userPhoto?.width}
                    height={userPhoto?.height}
                  ></Styled.ImageProfile>
                  <Styled.UserImageLines
                    src="/images/user-image-lines.png"
                    alt=""
                    width="408"
                    height="554"
                    side="left"
                  ></Styled.UserImageLines>
                </div>
              </Styled.ImageProfileContainer>
            )}
          </Styled.UserDetails>

          <div style={{ paddingBottom: '90px' }}>
            <Styled.TitleContainer>
              <StyledFormSection.SectionFormTitle styledProfile={true}>
                About your work
              </StyledFormSection.SectionFormTitle>
              <Styled.InternalLink to="/profile/1" style={{ height: 'fit-content' }}>
                Back to edit
              </Styled.InternalLink>
            </Styled.TitleContainer>

            <Styled.WorkContainer>
              <div style={{ maxWidth: '40ch', width: '100%' }}>
                <div>
                  <Styled.Label>Core sector:</Styled.Label>
                  <Styled.Description>{mainSectorTypeValue || 'No data'}</Styled.Description>
                </div>
                <div>
                  <Styled.Label>Disciplines:</Styled.Label>
                  <Styled.Description>{artTypesValue || 'No data'}</Styled.Description>
                </div>
                <div>
                  <Styled.Label>Focus:</Styled.Label>
                  <Styled.Description>{themesValue || 'No data'}</Styled.Description>
                </div>
                <div>
                  <Styled.Label>Scale:</Styled.Label>
                  <Styled.Description>{workScaleValue || 'No data'}</Styled.Description>
                </div>
                <div>
                  <Styled.Label>Area of Operation:</Styled.Label>
                  <Styled.Description>{conutriesValue || 'No data'}</Styled.Description>
                </div>
              </div>
              <div style={{ width: '100%' }}>
                <div>
                  <Styled.Label>Website URL:</Styled.Label>
                  <Styled.LinksContainer>
                    {websiteWithProtocol ? (
                      <Styled.ExternalLink
                        href={websiteWithProtocol}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {websiteWithProtocol}
                      </Styled.ExternalLink>
                    ) : (
                      <Styled.Description>No data</Styled.Description>
                    )}
                  </Styled.LinksContainer>
                </div>

                <div>
                  <Styled.Label>Social media:</Styled.Label>
                  <Styled.LinksContainer>
                    {socialMediaLinksWithProtocol?.length ? (
                      socialMediaLinksWithProtocol.map((sm, idx) => {
                        return (
                          <Styled.ExternalLink
                            key={idx}
                            href={sm.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {sm.link}
                          </Styled.ExternalLink>
                        );
                      })
                    ) : (
                      <Styled.Description>No data</Styled.Description>
                    )}
                  </Styled.LinksContainer>
                </div>

                <div style={{ width: '100%' }}>
                  <Styled.Label>Work links:</Styled.Label>
                  <Styled.LinksContainer>
                    {workLinksWithProtocol?.length ? (
                      workLinksWithProtocol.map((work, idx) => {
                        return (
                          <Styled.ExternalLink
                            key={idx}
                            href={work.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {work.link}
                          </Styled.ExternalLink>
                        );
                      })
                    ) : (
                      <Styled.Description>No data</Styled.Description>
                    )}
                  </Styled.LinksContainer>
                </div>
              </div>
              <Styled.WorkImagesContainer>
                <p className="sr-only">Images Of Work:</p>
                {userImagesOfWork?.length >= 1 ? (
                  <Styled.ThreeLinesImage
                    src="/images/three-lines.png"
                    alt=""
                    width="407"
                    height="553"
                    side="right"
                  ></Styled.ThreeLinesImage>
                ) : null}

                {userImagesOfWork?.length >= 2 ? (
                  <Styled.ThreeLinesImage
                    src="/images/three-lines.png"
                    alt=""
                    width="407"
                    height="553"
                    side="left"
                  ></Styled.ThreeLinesImage>
                ) : null}

                {userImagesOfWork?.length
                  ? userImagesOfWork?.map((imageOfWork, idx) => {
                      return (
                        <Styled.ImagesOfWork
                          idx={idx}
                          src={imageOfWork?.url}
                          alt=""
                          width={imageOfWork?.width}
                          height={imageOfWork?.height}
                        ></Styled.ImagesOfWork>
                      );
                    })
                  : null}
              </Styled.WorkImagesContainer>
            </Styled.WorkContainer>
          </div>

          <div>
            <Styled.TitleContainer>
              <StyledFormSection.SectionFormTitle styledProfile={true}>
                Your network
              </StyledFormSection.SectionFormTitle>
              <Styled.InternalLink to="/profile/2" style={{ height: 'fit-content' }}>
                Back to edit
              </Styled.InternalLink>
            </Styled.TitleContainer>
            <ul
              style={{
                display: 'flex',
                listStyle: 'none',
                margin: '0',
                padding: '0',
                flexWrap: 'wrap',
                rowGap: '32px',
              }}
            >
              {linkedUsers?.length ? (
                linkedUsers?.map((linkedUser, idx) => {
                  return (
                    <>
                      <ProfileCard
                        isClickableEl={false}
                        key={linkedUser.id}
                        userData={linkedUser}
                        namePositionDown={true}
                        desktopImageSize="M"
                        mobileImageSize="M"
                        widthAuto={true}
                        imageUrl={linkedUser?.image?.formats?.small?.url || linkedUser?.image?.url}
                        width={linkedUser?.image?.formats?.small?.width || linkedUser?.image?.width}
                        height={
                          linkedUser?.image?.formats?.small?.height || linkedUser?.image?.height
                        }
                      ></ProfileCard>
                    </>
                  );
                })
              ) : (
                <Styled.Description as="li">No linked users</Styled.Description>
              )}
            </ul>
          </div>
        </Styled.ProfileContainer>

        <Styled.ImageOrange
          src="/images/orange.png"
          alt=""
          width="1013"
          height="1070"
        ></Styled.ImageOrange>

        <Styled.ImageDottedBlue
          src="/images/dotted-blue.png"
          alt=""
          width="917"
          height="1074"
        ></Styled.ImageDottedBlue>
      </Styled.ProfileContainerWrapper>

      <FormSection title="Account details">
        {isOrganization && (
          <div style={{ paddingBottom: '35px' }}>
            <StepInputText
              label="Name of contact person"
              id="organizationContactPersonName"
              inputType="text"
              register={register}
              placeholder="Name"
              errors={errors}
              flexDirection="row"
              maxWidthType="large"
              maxLength={DEFAULT_INPUT_MAX_LENGTH}
            />
          </div>
        )}

        {!data.id ? (
          <StepInputText
            label="Contact email*"
            id="email"
            autocomplete="email"
            inputType="email"
            description="This email address will only be used to contact you and will not be published."
            register={register}
            placeholder="your contact email"
            errors={errors}
            descriptionDown={true}
            flexDirection="row"
            maxWidthType="large"
            maxLength={DEFAULT_INPUT_MAX_LENGTH}
          />
        ) : (
          <p style={{ marginTop: 0, paddingBottom: '16px' }}>
            The account email can't be modified. <br />
            Please contact{' '}
            <a href="mailto:info@community-arts.net" target="_blank" rel="noreferrer">
              info@community-arts.net
            </a>{' '}
            if needed.
          </p>
        )}

        <StepInputText
          label="Public email"
          id="publicEmail"
          autocomplete="email"
          inputType="email"
          description="This email address will be publicly reflected in your membership profile."
          register={register}
          placeholder="Public email"
          errors={errors}
          descriptionDown={true}
          flexDirection="row"
          maxWidthType="large"
          maxLength={DEFAULT_INPUT_MAX_LENGTH}
        />

        <StepInputText
          label={'Password' + (!data.id || data.id === '' ? '*' : '')}
          id="password"
          inputType="password"
          autocomplete="new-password"
          register={register}
          placeholder="at least 6 characters"
          errors={errors}
          flexDirection="row"
          maxWidthType="large"
          maxLength={DEFAULT_INPUT_MAX_LENGTH}
        />

        <div style={{ paddingTop: '35px' }}>
          <StepInputText
            label={'Password confirm' + (!data.id || data.id === '' ? '*' : '')}
            id="repeatPassword"
            inputType="password"
            autocomplete="repeat-password"
            register={register}
            placeholder="Repeat password"
            errors={errors}
            flexDirection="row"
            maxWidthType="large"
            maxLength={DEFAULT_INPUT_MAX_LENGTH}
          />
        </div>

        {data.id && (
          <>
            <StyledFormSection.SectionFormDescription>
              Leave blank if you don't want to edit it.
            </StyledFormSection.SectionFormDescription>

            <p>
              You can{' '}
              <button
                type="button"
                style={{
                  textDecoration: 'underline',
                  color: COLORS.BLUE,
                  appearance: 'none',
                  background: 'none',
                  border: 'none',
                  fontSize: 'inherit',
                  padding: 0,
                }}
                onClick={showTermsOfUseInfoOnlyModal}
              >
                review the terms of use
              </button>
              .
            </p>
          </>
        )}

        <div style={{ position: 'absolute', inset: 0, overflow: 'hidden', pointerEvents: 'none' }}>
          <Styled.ImageYellow
            src="/images/yellow.png"
            alt=""
            width="1342"
            height="1285"
          ></Styled.ImageYellow>
        </div>
      </FormSection>

      <FormSection title="Please choose one of the options*">
        <StyledFormSection.SectionFormDescription bigSize={true} style={{ textAlign: 'left' }}>
          In order to take full advantage of your CAN membership, connect you with our members and
          keep you up to date, we use social media and would also like to introduce you and your
          activities via our channels. We need your consent for this. Please choose one of the
          options:
        </StyledFormSection.SectionFormDescription>

        <ConsentForm
          watch={watch}
          register={register}
          errors={errors}
          canSocialMediaType={canSocialMediaType}
          isOrganization={isOrganization}
        />

        <Styled.ConsentFooter>
          You can revoke your consent at any time. You can find further information on this in our{' '}
          <a
            href="/privacy-policy"
            target="_blank"
            style={{ color: COLORS.DARK_GREY, textUnderlineOffset: '2px' }}
          >
            Privacy Policy
          </a>
          .
        </Styled.ConsentFooter>
      </FormSection>

      <FormSection title="Confirmation for the use of images*">
        <ImagesConsentForm
          register={register}
          errors={errors}
          description={
            isOrganization
              ? 'You confirm that you are authorized to legally represent the organisation and that the organization has all rights to the uploaded images.'
              : 'You confirm that you are of legal age and have all rights to the uploaded images. CAN reserves the right not to accept your application or to delete some of your uploaded images.'
          }
        ></ImagesConsentForm>
      </FormSection>
    </div>
  );
};

export default FourthStep;
