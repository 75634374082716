import React from 'react';
import { COLORS } from '../../../utils/theme';

import FormSection from '../FormSection';

import StepHeader from '../StepHeader';
import StepInputText from '../StepInputText';
import StepInputSelect from '../StepInputSelect';
import StepInputTextarea from '../StepInputTextarea';
import StepInputFile from '../StepInputFile';
import StepInputSingleCheckbox from '../StepInputSingleCheckbox';
import { TEXTS } from '../../../utils/texts';

import * as StyledFormSection from '../FormSection/styles';
import * as Styled from './styles';

import { SHORT_INPUT_MAX_LENGTH, DEFAULT_INPUT_MAX_LENGTH } from '../../../utils/constants';

const FirstStep = ({
  errors,
  register,
  countries,
  typeOfMember,
  individualProfile,
  organizationType,
  userInfo,
  control,
  watch,
  setValue,
  step,
  imageInitialId,
  logoInitialId,
  gender,
  isOrganization,
  isMobile,
  isNewProfile,
}) => {
  const countriesWithoutRegions = countries?.data?.filter(
    (country) => !country.attributes.isoCode.includes('R_'),
  );

  const individualProfilesOtherId = String(
    individualProfile?.data?.find((el) => el.attributes.name?.toLowerCase() === 'other')?.id,
  );
  const individualProfilesSelectedId = watch('individualProfiles');
  const showIndividualProfilesOther = individualProfilesSelectedId?.length
    ? individualProfilesSelectedId?.includes(Number(individualProfilesOtherId))
    : false;

  const genderOtherId = String(
    gender?.data?.find((el) => el.attributes.name?.toLowerCase() === 'other')?.id,
  );
  const genderSelectedId = watch('gender');
  const showGenderOther = genderSelectedId
    ? Number(genderSelectedId) === Number(genderOtherId)
    : false;

  const organizationTypeOtherId = String(
    organizationType?.data?.find((el) => el.attributes.name?.toLowerCase() === 'other')?.id,
  );
  const organizationTypeSelectedId = watch('organizationType');
  const showOrganizationTypeOther = organizationTypeSelectedId?.length
    ? organizationTypeSelectedId?.includes(Number(organizationTypeOtherId))
    : false;

  return (
    <div>
      <StepHeader
        title={isOrganization ? 'About <span>your</span> organisation' : 'About <span>you</span>'}
        subtitle={
          isOrganization
            ? '<span>Tell us a bit about your organisation.</span>'
            : '<span>Tell us a bit about yourself.</span>'
        }
        step={step}
        bgColor={COLORS.YELLOW}
      ></StepHeader>

      <FormSection title="Basic Info">
        <StepInputSelect
          label={`I identify myself as...${isNewProfile ? '*' : ''}`}
          id="typeOfMember"
          register={register}
          control={control}
          disabled={!isNewProfile}
          placeholder="Type"
          hideSearchInput={true}
          options={typeOfMember?.data?.map((el) => ({
            name: el?.attributes?.label,
            id: el?.id,
          }))}
          errors={errors}
        />

        <StyledFormSection.SectionFormDescription style={{ color: isNewProfile && 'red' }}>
          {isNewProfile
            ? 'Modifying this field will erase already filled out information in the form'
            : "The field can't be modified. Please contact info@community-arts.net if needed."}
        </StyledFormSection.SectionFormDescription>

        {isOrganization ? (
          <Styled.Fieldset>
            <Styled.Legend>Organisation's name is*</Styled.Legend>
            <StepInputText
              hideLabel={true}
              label="Organisation name"
              id="organizationName"
              autocomplete="off"
              register={register}
              placeholder="Organisation Name"
              maxLength={DEFAULT_INPUT_MAX_LENGTH}
              errors={errors}
            />
          </Styled.Fieldset>
        ) : (
          <Styled.Fieldset>
            <Styled.Legend>My name is*</Styled.Legend>
            <StepInputText
              hideLabel={true}
              label="First name"
              id="firstName"
              autocomplete="given-name"
              register={register}
              placeholder="First name"
              maxLength={SHORT_INPUT_MAX_LENGTH}
              errors={errors}
            />
            <StepInputText
              hideLabel={true}
              label="Last name"
              id="lastName"
              autocomplete="family-name"
              register={register}
              placeholder="Last name"
              maxLength={SHORT_INPUT_MAX_LENGTH}
              errors={errors}
            />
          </Styled.Fieldset>
        )}

        {!isOrganization && (
          <>
            <StepInputSelect
              label="CAN can refer to me as"
              id="gender"
              hideSearchInput={true}
              register={register}
              control={control}
              placeholder="Pronoun"
              options={gender?.data?.map((el) => ({
                name: el?.attributes?.name,
                id: el?.id,
              }))}
              errors={errors}
            />

            {showGenderOther && (
              <div style={{ marginTop: '-20px' }}>
                <StepInputText
                  label="Other gender"
                  id="genderOther"
                  placeholder="Other pronouns"
                  hideLabel={true}
                  register={register}
                  maxLength={DEFAULT_INPUT_MAX_LENGTH}
                />
              </div>
            )}
          </>
        )}

        {isOrganization ? (
          <>
            <StepInputSelect
              label="We are a..."
              placeholder="Type"
              id="organizationType"
              register={register}
              control={control}
              multiple={true}
              options={organizationType?.data?.map((el) => ({
                name: el?.attributes?.name,
                id: el?.id,
              }))}
              errors={errors}
            />
            {showOrganizationTypeOther && (
              <div style={{ marginTop: '-20px' }}>
                <StepInputText
                  label="Other type"
                  id="organizationTypeOther"
                  placeholder="Other type"
                  hideLabel={true}
                  register={register}
                  maxLength={DEFAULT_INPUT_MAX_LENGTH}
                />
              </div>
            )}
          </>
        ) : (
          <>
            <StepInputSelect
              label="I'm a/an"
              id="individualProfiles"
              register={register}
              control={control}
              placeholder="Role(s)"
              multiple={true}
              options={individualProfile?.data?.map((el) => ({
                name: el?.attributes?.name,
                id: el?.id,
              }))}
              errors={errors}
            />
            {showIndividualProfilesOther && (
              <div style={{ marginTop: '-20px' }}>
                <StepInputText
                  label="Other profile"
                  id="individualProfilesOther"
                  placeholder="Other profile"
                  hideLabel={true}
                  register={register}
                  maxLength={DEFAULT_INPUT_MAX_LENGTH}
                />
              </div>
            )}
          </>
        )}

        <StepInputSelect
          label="Based in"
          id="basedIn"
          register={register}
          control={control}
          placeholder="Country"
          options={countriesWithoutRegions?.map((el) => ({
            name: el?.attributes?.name,
            id: el?.id,
          }))}
          errors={errors}
        />

        <div style={{ position: 'absolute', inset: 0, overflow: 'hidden', pointerEvents: 'none' }}>
          <Styled.ImageCloud src="/images/cloud-blue.png" alt="928" width="992"></Styled.ImageCloud>
        </div>
      </FormSection>

      <FormSection title="Summary bio">
        <StyledFormSection.SectionFormDescription style={{ paddingBottom: '40px' }}>
          Please share your {isOrganization && "organisation's"} story using between 200 and 1000
          characters.
        </StyledFormSection.SectionFormDescription>

        <Styled.TextareaDescriptionContainer>
          <div style={{ width: 'fit-content' }}>
            <Styled.ListTitle>Please make sure to:</Styled.ListTitle>
            <Styled.ListOptions>
              <Styled.Option>
                <Styled.CheckImage
                  src="/images/check-purple.svg"
                  alt=""
                  width="14"
                  height="10"
                ></Styled.CheckImage>
                {isOrganization
                  ? "Explain your organisation's connection to the community arts and the extent to which arts for social impact is integrated in its work."
                  : 'Explain your connection to arts for social impact/community arts and if it is integrated in your work.'}
              </Styled.Option>
            </Styled.ListOptions>
          </div>

          <div style={{ width: 'fit-content' }}>
            <Styled.ListTitle>
              {isOrganization ? 'You could also include:' : 'Other aspects you can include:'}
            </Styled.ListTitle>
            <Styled.ListOptions>
              <Styled.Option>
                <Styled.CheckImage
                  src="/images/check-purple.svg"
                  alt=""
                  width="14"
                  height="10"
                ></Styled.CheckImage>
                {isOrganization
                  ? "Milestones of your organisation's work to date."
                  : 'Milestones of your work to date.'}
              </Styled.Option>
              <Styled.Option>
                <Styled.CheckImage
                  src="/images/check-purple.svg"
                  alt=""
                  width="14"
                  height="10"
                ></Styled.CheckImage>
                A description of the communities, audiences or target groups you work with/have
                worked with (e.g. migrants, youth, people with disabilities, general public, etc).
              </Styled.Option>
              <Styled.Option>
                <Styled.CheckImage
                  src="/images/check-purple.svg"
                  alt=""
                  width="14"
                  height="10"
                ></Styled.CheckImage>
                If you work with artists.
              </Styled.Option>
            </Styled.ListOptions>
          </div>
        </Styled.TextareaDescriptionContainer>

        <StepInputTextarea
          label="Biography"
          id="summary"
          register={register}
          watch={watch}
          placeholder="Write your bio here... Key words are useful for members searching the network for profiles like yours. Please include them in your summary."
          errors={errors}
          showCharacterCount
        />

        <div style={{ position: 'absolute', inset: 0, overflow: 'hidden', pointerEvents: 'none' }}>
          <Styled.ImageYellow src="/images/yellow.png" alt="1342" width="1285"></Styled.ImageYellow>
        </div>
      </FormSection>

      <FormSection title={isOrganization ? 'Your logo' : 'Your portrait'}>
        <StyledFormSection.SectionFormDescription>
          {isOrganization
            ? "Please share a high resolution image of your organisation's logo"
            : 'Please share a high-resolution photograph of yourself'}{' '}
          <br />
          (at least 960x960px).
        </StyledFormSection.SectionFormDescription>

        {isOrganization ? (
          <div key="logo">
            <StepInputFile
              label="Choose a logo"
              id="logo"
              watch={watch}
              userInfo={userInfo}
              setValue={setValue}
              initialIds={logoInitialId}
              isMobile={isMobile}
            >
              <Styled.CaptionsWrapper>
                <div className="captions">
                  <p style={{ margin: 0 }}>Image size: up to 10MB</p>
                  <p style={{ margin: 0 }}>Files supported: .png or .jpg</p>
                  <p style={{ margin: 0 }}>Square pictures work best</p>
                </div>
                <div className="note">
                  <p>
                    Note: The image will be resized to fit a square format; however, CAN will retain
                    and save the image in its original format.
                  </p>
                </div>
              </Styled.CaptionsWrapper>
            </StepInputFile>

            <div style={{ marginTop: '2rem' }}>
              <StepInputSingleCheckbox
                id="logoOwnerConfirmation"
                label={TEXTS.profile.getOwnerConfirmation(isOrganization)}
                register={register}
                errors={errors}
              ></StepInputSingleCheckbox>
            </div>
          </div>
        ) : (
          <div key="image">
            <StepInputFile
              label="Choose image"
              id="image"
              watch={watch}
              userInfo={userInfo}
              setValue={setValue}
              initialIds={imageInitialId}
              isMobile={isMobile}
            >
              <Styled.CaptionsWrapper>
                <div className="captions">
                  <p style={{ margin: 0 }}>Image size: up to 10MB</p>
                  <p style={{ margin: 0 }}>Files supported: .png or .jpg</p>
                  <p style={{ margin: 0 }}>Square pictures work best</p>
                </div>
                <div className="note">
                  <p>
                    Note: The image will be resized to fit a square format; however, CAN will retain
                    and save the image in its original format.
                  </p>
                </div>
              </Styled.CaptionsWrapper>
            </StepInputFile>

            <div style={{ marginTop: '2rem' }}>
              <StepInputSingleCheckbox
                id="imageOwnerConfirmation"
                label={TEXTS.profile.getOwnerConfirmation(isOrganization)}
                register={register}
                errors={errors}
              ></StepInputSingleCheckbox>
            </div>

            <div style={{ marginTop: '30px' }}>
              <StepInputTextarea
                label="Photo information"
                id="imageCredits"
                placeholder="Photo information"
                maxWidthType="480px"
                register={register}
                errors={errors}
                watch={watch}
                rows={1}
                maxLength={DEFAULT_INPUT_MAX_LENGTH}
              />
            </div>
            <StyledFormSection.SectionFormDescription style={{ marginTop: '0.2rem' }}>
              eg: a photo credit, location of the event, etc.
            </StyledFormSection.SectionFormDescription>
          </div>
        )}

        <div style={{ position: 'absolute', inset: 0, overflow: 'hidden', pointerEvents: 'none' }}>
          <Styled.ImageDotted
            src="/images/dotted-blue.png"
            alt="917"
            width="1074"
          ></Styled.ImageDotted>
        </div>
      </FormSection>
    </div>
  );
};

export default FirstStep;
